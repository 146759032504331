import {createReducer, on} from '@ngrx/store';

import {SchooldashboardNewState} from './schooldashboard.state';
import {
  resetSchooldashboard,
  setBazenbanner, setSchooldashboardOnderwijssoortDetails,
  setSchooldashboardOnderwijssoorten,
  setSchooldashboardVakDetails,
  setSchooldashboardVakken
} from './schooldashboard.actions';
import { resetBazenbanner, toggleGroepering } from '../dashboardsettings/dashboardsettings.actions';

const initialState: SchooldashboardNewState = {
  bazenbanner: null,
  vakken: [],
  onderwijssoorten: []
};

export const schooldashboardNewReducer = createReducer(
    initialState,
    on(setSchooldashboardVakken, (state, {value}) => ({...state, vakken: value.map(v => ({header: v, content: null}))})),
    on(setSchooldashboardVakDetails, (state, {vakdetails, naam}) => ({
      ...state, vakken: state.vakken.map(v => {
          if (v.header.naam === naam) {
            return ({...v, content: vakdetails});
          }
          return v;
      })})
    ),

    on(setSchooldashboardOnderwijssoorten, (state, {value}) => ({...state, onderwijssoorten: value.map(v => ({header: v, content: null}))})),
    on(setSchooldashboardOnderwijssoortDetails, (state, {onderwijssoortdetails, naam}) => ({
      ...state, onderwijssoorten: state.onderwijssoorten.map(v => {
        if (v.header.naam === naam) {
          return ({...v, content: onderwijssoortdetails});
        }
        return v;
      })})
    ),

    on(setBazenbanner, (state, {value}) => ({...state, bazenbanner: value})),
    on(resetBazenbanner, (state) => ({ ...state, bazenbanner: null })),

    on(resetSchooldashboard, _ => initialState)
);
