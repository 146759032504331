import {Component, Inject, Renderer2, Type} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {OauthConfigurationService} from './services/oauth.configuration.service';
import {AnalyticsService, Metrics} from './services/analytics.service';
import {ModalwindowService} from './services/modalwindow.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {DOCUMENT} from '@angular/common';
import {AppState} from './state/app.state';
import {Store} from '@ngrx/store';
import {appInit} from './state/app.actions';
import {LeermiddelvakkoppelingFacade} from './old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { LeermiddelvakkoppelingNewFacade } from './state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import {SubMenu} from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Somtoday Connect';
  icon = '';
  component: Type<Component>;
  iconFont = false;
  parameterSubscription: Subscription;
  toonHelp = false;
  modalWindowOnderwerp: string;
  leermiddelVakKoppelingOpen = false;
  subMenu: SubMenu;

  constructor(private toastrService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private oauthConfigurationService: OauthConfigurationService,
              private modalWindowService: ModalwindowService,
              private analyticsService: AnalyticsService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document,
              private store: Store<AppState>,
              private leermiddelVakKoppelingStore: LeermiddelvakkoppelingFacade,
              public leermiddelvakkoppelingNew: LeermiddelvakkoppelingNewFacade) {
    this.oauthConfigurationService.loadConfiguration();

    this.parameterSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)).subscribe( event => {
        this.analyticsService.logEvent(Metrics.PAGELOAD, (event as NavigationEnd).urlAfterRedirects);
      });

    this.parameterSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      this.subMenu = event.subMenu;
      this.title = event.subtitle;
      this.icon = event.icon;
      this.component = event.component;
      this.iconFont = event.iconFont;
    });

    this.modalWindowService.onderwerp$.subscribe(onderwerp => {
      this.modalWindowOnderwerp = onderwerp;
      this.toonHelp = true;
      this.renderer.addClass(this.document.body, 'scroll-lock-voor-modal-window');
    });

    this.modalWindowService.close$.subscribe(close =>
    {
      this.toonHelp = !close;
      if (close) {
        this.renderer.removeClass(this.document.body, 'scroll-lock-voor-modal-window');
      }
    });

    this.leermiddelvakkoppelingNew.isOpen().subscribe(open => {
      if (open) {
        this.renderer.addClass(this.document.body, 'scroll-lock-voor-modal-window');
      }
      else {
        this.renderer.removeClass(this.document.body, 'scroll-lock-voor-modal-window');
      }
    });

    moment.locale('nl');

    this.store.dispatch(appInit());

    this.leermiddelVakKoppelingStore.selectLeermiddelVakKoppelingOpen().subscribe(value => {
      this.leermiddelVakKoppelingOpen = value;
      if (this.leermiddelVakKoppelingOpen) {
        this.renderer.addClass(this.document.body, 'scroll-lock-voor-modal-window');
      }
      else {
        this.renderer.removeClass(this.document.body, 'scroll-lock-voor-modal-window');
      }
    });
  }
}
