<div (click)="openMenu('Main')" class="menu-toggle">
  <i class="icon-menu"></i>
</div>

<div [ngClass]="gridClass" *ngIf="!isLandelijkBeheerSupport; else emptyMenu">
  <div class="home" (click)=onHomeClicked()>
    <i class="connect-logo"></i>
    <span class="menu-text">Connect</span>
    <i (click)="closeMenu('Main', $event)" class="menu-close icon-exit"></i>
  </div>

  <ng-container *ngIf="magLeermiddelDashboardsInzien">
    <div class="menu-item schooldashboard" (click)="onClick('schooldashboard', 'schooldashboard')"
      [class.active]="isRouterLinkActive(['schooldashboard', 'vakdashboard', 'vakoverstijgenddashboard', 'lesgroepdashboard'])"
      cy="schooldashboard">
      <i class="schooldashboard-logo"></i><br />
      <span class="menu-text show-for-phone-only">Schooldashboard</span>
    </div>

    <div *ngIf="isNietProductieOfTest()" class="menu-item schooldashboard"
      (click)="onClick('schooldashboardnew', 'schooldashboardnew')"
      [class.active]="isRouterLinkActive(['schooldashboardnew', 'vakdashboardnew', 'lesgroepdashboardnew'])">
      <i class="schooldashboard-logo"></i><br />
      <span class="menu-text show-for-phone-only">Schooldashboard new</span>
    </div>

    <div *ngIf="isNietProductieOfTest()" class="menu-item leermiddelendashboard"
      (click)="onClick('leermiddelendashboard', 'leermiddelendashboard')"
      [class.active]="isRouterLinkActive(['leermiddelendashboard'])">
      <i class="leermiddelendashboard-logo"></i><br />
      <span class="menu-text show-for-phone-only">Leermiddelendashboard</span>
    </div>

    <div class="menu-item studiewijzerlinkjesdashboard"
      (click)="onClick('studiewijzerlinkjes', 'studiewijzerlinkjesdashboard')"
      [class.active]="isRouterLinkActive(['studiewijzerlinkjesdashboard'])">
      <i class="studiewijzerlinkjesdashboard-logo"></i><br />
      <span class="menu-text show-for-phone-only">Studiewijzerlinkjes</span>
    </div>

    <div *ngIf="isNietProductieOfTest()" class="menu-item studiewijzerlinkjesdashboard"
      (click)="onClick('studiewijzerlinkjesnew', 'studiewijzerlinkjesdashboardnew')"
      [class.active]="isRouterLinkActive(['studiewijzerlinkjesdashboardnew'])">
      <i class="studiewijzerlinkjesdashboard-logo"></i><br />
      <span class="menu-text show-for-phone-only">Studiewijzerlinkjes new</span>
    </div>

  </ng-container>

  <div *ngIf="magAVGDashboardInzien" class="menu-item privacydashboard" cy="privacydashboard"
    (click)="onClick('privacydashboard', 'privacydashboard')" [class.active]="isRouterLinkActive(['privacydashboard'])">
    <i class="privacydashboard-logo"></i><br />
    <span class="menu-text show-for-phone-only">Privacydashboard</span>
  </div>

  <div *ngIf="magBeperkteAVGDashboardInzien" class="menu-item privacydashboard"
    (click)="onClick('privacydashboard-preview', 'privacydashboard-preview')"
    [class.active]="isRouterLinkActive(['privacydashboard-preview'])">
    <i class="beperkte-avg-logo"></i><br />
    <span class="menu-text show-for-phone-only">Privacydashboard</span>
  </div>

  <!-- Deze div zorgt ervoor dat de onderste div onderaan de pagina terecht komt -->
  <div></div>

  <div class="end-align-content">
    <app-whatsnew class="menu-item"></app-whatsnew>
    <app-vestigingsinstellingen class="menu-item" *ngIf="magLeermiddelDashboardsInzien"></app-vestigingsinstellingen>
    <div class="menu-item back-to-somtoday" (click)="somtodayButtonClick()">
      <i class="terug-somtoday" title="Terug naar Somtoday"></i>
      <span class="menu-text show-for-phone-only">Terug naar Somtoday</span>
    </div>
    <span class="buildMoment">{{buildMoment}}</span>
  </div>
</div>
<ng-template #emptyMenu>
  <div [ngClass]="gridClass">
    <div class="home">
      <i class="connect-logo"></i>
      <span class="menu-text">Connect</span>
      <i class="menu-close icon-exit"></i>
    </div>
  </div>
</ng-template>
