import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from 'rxjs';
import {TableModel} from '../../table/table/table.model';
import {ModalwindowService} from '../../services/modalwindow.service';
import {decode, encode} from '../../services/url.encode.service';
import {LesGroepDagGebruikData, VakData} from '../state/vakdashboard/vakdashboard.selectors';
import {FilterService} from '../../services/filter.service';
import {SchooldashboardFacade} from '../state/schooldashboard/schooldashboard.facade';
import {Params} from '@angular/router';
import {UrlObject} from 'query-string';

@Component({
  selector: 'app-vakdashboard',
  templateUrl: './vakdashboard.component.html',
  styleUrls: ['./vakdashboard.component.scss']
})
export class VakdashboardComponent implements OnInit, OnDestroy {
  vakData: VakData;
  model: TableModel<LesGroepDagGebruikData>;

  loading = this.store.selectLoading();

  get volgendeUri(): string {
    const {vak, onderwijssoort} = this.vakData?.nav?.volgende || {};
    return `/vakdashboard/${encode(vak)}/${encode(onderwijssoort)}`;
  }
  get vorigeUri(): string {
    const {vak, onderwijssoort} = this.vakData?.nav?.vorige || {};
    return `/vakdashboard/${encode(vak)}/${encode(onderwijssoort)}`;
  }

  get hoofdNiveau(): string {
    return this.vakData.hoofdniveau;
  }

  get subNiveau(): string {
    return this.vakData.subniveau;
  }

  subscriptions: Subscription[];

  constructor(
    private modalwindowService: ModalwindowService,
    private store: SchooldashboardFacade,
    private filterService: FilterService) {
  }

  ngOnInit() {
    let loadingDocenten = false;
    let loadingMedewerkers = false;
    this.subscriptions = [
      combineLatest([
        this.store.selectVakDataMetDocenten(),
        this.store.selectLoadDocenten(),
        this.store.selectLoadMedewerkers(),
        this.store.selectDocentenVanVak()
      ])
      .subscribe(([data, shouldLoadDocenten, shouldLoadMedewerkers, uuids]) => {
        this.vakData = data;
        if (shouldLoadDocenten && !loadingDocenten) {
          loadingDocenten = true;
          this.store.loadDocenten();
        }
        if (shouldLoadMedewerkers && uuids?.length > 0 && !loadingMedewerkers) {
          loadingMedewerkers = true;
          this.store.loadMedewerkers(uuids);
        }
      })
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  navigeerNaarLesgroep({lesgroepUuid}: LesGroepDagGebruikData) {
    this.filterService.transformFilterToPath((_, {vak, onderwijssoort}, query) => ({
      url: `/lesgroepdashboard/${vak}/${onderwijssoort}/${lesgroepUuid}`, query
    }));
  }

  goBack(_, {vak, onderwijssoort}: Params, query: Params): UrlObject {
    const update = (a: string | string[], v: string) =>
      a === undefined ? [v] : Array.isArray(a) ? [...new Set([...a, v])] : [...new Set([a, v])];
    vak = decode(vak);
    onderwijssoort = decode(onderwijssoort);
    return {
      url: 'schooldashboard',
      query: {...query, vak: update(query.vak, vak), ows: update(query.ows, onderwijssoort)},
      fragmentIdentifier: query.groeperenOpVak === 'false' ? `${onderwijssoort}-${vak}` : `${vak}-${onderwijssoort}`
    };
  }
}
