import { ConnectConfiguratie } from './environment.service';

class RegressieConfiguratie extends ConnectConfiguratie {
  configurationName(): string {
    return 'Regressie';
  }

  production(): boolean {
    return false;
  }

  DEBUG(): boolean {
    return true;
  }

  getPrefix(): string {
    return null;
  }
  isSecure(): boolean {
    return true;
  }
  idpUrl(): string {
    return `https://inloggen.regressie.somtoday.build`;
  }
  idpIssuer(): string {
    return `https://regressie.somtoday.build`;
  }
  apiUrl(): string {
    // initial not available
    return `https://pr-dot-sc-nightly-regressie.ew.r.appspot.com/`;
  }
  frontendUrl(): string {
    return `https://connect.regressie.somtoday.build/oauth`;
  }

  public wiserClientId(): string {
    return 'connect-regressie-somtoday-build';
  }

  validationJwks(): object {
    return {keys: [{kty: 'RSA', 'x5t#S256': 'gGVCqlDTtRZ9Ff9SNfRffwp6DxeCi5Du2iA8qHTL7tc', e: 'AQAB', use: 'sig', kid: 'iridiumidp-2317886525721919933839597731474144504', x5c: ['MIIDUTCCAjkCEAG+aKEBjxEeTVoQKTORfPgwDQYJKoZIhvcNAQELBQAwZzELMAkGA1UEBhMCTkwxGjAYBgNVBAoMEVRvcGljdXMuRWR1Y2F0aW9uMREwDwYDVQQLDAhTb210b2RheTEpMCcGA1UEAwwgU29tdG9kYXkgUmVncmVzc2llIEF1dGhlbnRpY2F0b3IwHhcNMjIxMjEzMDkzNzQ4WhcNMjcxMjMxMjI1OTU5WjBnMQswCQYDVQQGEwJOTDEaMBgGA1UECgwRVG9waWN1cy5FZHVjYXRpb24xETAPBgNVBAsMCFNvbXRvZGF5MSkwJwYDVQQDDCBTb210b2RheSBSZWdyZXNzaWUgQXV0aGVudGljYXRvcjCCASIwDQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAK0Lvg4g0upOHDf8dakVFgXSEhataEmrum45cdmqCRaM0ztzYvPoOUJobQOGlxz6Fx1wxRaXoxY+euhzwN0+A5vMLIUfvixakNetQg8S7RKVzQ998gKWJvlflpoDvABzPte5wCzGusLdmkd+cIJYki/ugE3GL4mjgR2hnrdxk+A9Nh/oEqf7t1ygesJgmhfDI5FMOrk+QSLHCs/dIhE4uWx6Dn+oBBrUzosaebHIIPhpS0WwwXPytZF2MR780MNKuUHMcrzJlCTPJRe4ppjb7Hm8zeQSrmlNP0yCgjx83nLmqC2x336IKxItTjjONZDtak2YCL3JJw4W2gfyo/pXxD8CAwEAATANBgkqhkiG9w0BAQsFAAOCAQEAWTKEj1Eh/iOmAygSIreCb3CNXMlViaV+WNIJtKHPLQ0k+2DfnbnfUQkjXlP5NZ58yP67MOEH7u3nDhPhoiNs/PiGGg2Yg7sKPGTi2VbmIshIUKVnRiPbWyP5X6rssT6iRWzNh9n0sBfMKz+Vsu5tFcW0hF3w6uTrp4vXG8ImD3mFPaw7dlHFiE17WuXiZdMtimxMo6+Xo/MHNxG8yI40A/1xXuK4yTs1wbgiilcoVbkI6iXRmazXDVh3pRtxR2xo/v/++BNCMwN44X+ANMgiYU9LlHCvSSSU+txK078LAF+CaSlq8V1xCndArm1jix3IUKGPy1HBXkvJGkKqCokCFw=='], alg: 'RS256', n: 'rQu-DiDS6k4cN_x1qRUWBdISFq1oSau6bjlx2aoJFozTO3Ni8-g5QmhtA4aXHPoXHXDFFpejFj566HPA3T4Dm8wshR--LFqQ161CDxLtEpXND33yApYm-V-WmgO8AHM-17nALMa6wt2aR35wgliSL-6ATcYviaOBHaGet3GT4D02H-gSp_u3XKB6wmCaF8MjkUw6uT5BIscKz90iETi5bHoOf6gEGtTOixp5scgg-GlLRbDBc_K1kXYxHvzQw0q5QcxyvMmUJM8lF7immNvsebzN5BKuaU0_TIKCPHzecuaoLbHffogrEi1OOM41kO1qTZgIvcknDhbaB_Kj-lfEPw'}]};
  }

  analyticsId(): string {
    return '';
  }

  bureauICEKoppelpartijUUID(): string {
    return '10d97656-ed1e-4588-9834-aab9b53924e5';
  }
}

const prConfig: RegressieConfiguratie = new RegressieConfiguratie();

export const environment = prConfig.build();
