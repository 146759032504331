export function encode(arg: string): string {
  return encodeURIComponent(arg).replace('(', '%28').replace(')', '%29');
}

export function encodeSlashes(arg: string): string {
  return arg?.replace('/', '%2F');
}

export function decode(arg: string): string {
  return decodeURIComponent(arg).replace('%2F', '/');
}

export function decodeSlashes(arg: string): string {
  return arg?.replace('%2F', '/');
}
