import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';

@Component({
  selector: 'app-vakdashboard-table-totaal-row',
  templateUrl: './vakdashboard-table-totaal-row.component.html',
  styleUrls: ['./vakdashboard-table-totaal-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakdashboardTableTotaalRowComponent implements OnInit {

  constructor(store: VakdashboardNewFacade) { }

  ngOnInit(): void {
  }

}
