import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy, ElementRef} from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-input-combobox',
    templateUrl: './input-combobox.component.html',
    styleUrls: ['./input-combobox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputComboboxComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: InputComboboxViewModel;

    public searchString: string = '';

    public filteredOptions: Observable<InputComboboxRow[]>;

    private filteredOptionsValue: InputComboboxRow[];

    private userTextInput: BehaviorSubject<string> = new BehaviorSubject('');

    public dropdownExpanded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private subs: Subscription[] = [];

    constructor(private elem: ElementRef) { }

    ngOnInit(): void {
        this.filteredOptions = combineLatest([this.viewModel.onGetOpties, this.userTextInput]).pipe(
            map(([opties, naam]) => {
                if (!!naam && naam !== '') {
                    if (this.viewModel.customSearch) {
                        return this.viewModel.customSearch(naam, opties);
                    }
                    return this.defaultSearch(naam, opties);
                }
                return opties;
            })
        );

        this.subs.push(this.viewModel.onGetSelected.subscribe(_ => {
          this.dropdownExpanded.next(false);
          this.searchString = '';
        }));

        this.subs.push(this.filteredOptions.subscribe(val => this.filteredOptionsValue = val));
    }

    ngOnDestroy(): void {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    public toggleDropdown(): void {
        if (!this.dropdownExpanded.value) {
          this.elem.nativeElement.querySelector('.optie.bodyContentBold')?.scrollIntoView({block: 'nearest'});
          this.userTextInput.next(this.searchString);
        } else {
          this.searchString = '';
        }
        this.dropdownExpanded.next(!this.dropdownExpanded.value);
    }

    public closeDropdown(): void {
        if (this.dropdownExpanded.value) {
            this.dropdownExpanded.next(false);
            this.searchString = '';
        }
    }

    public dropdownIconClass(): string {
        return 'arrow ' + this.dropdownExpanded.value ? 'open' : '';
    }

    public onType(): void {
        this.userTextInput.next(this.searchString);
        this.dropdownExpanded.next(true);
    }

    public onEnter(): void {
        if (this.filteredOptionsValue.length === 1) {
            this.dropdownExpanded.next(false);
            this.searchString = '';
            this.viewModel.doOnOptieSelected(this.filteredOptionsValue[0]);
        }
    }

    public defaultSearch(searchString: string, options: InputComboboxRow[]): InputComboboxRow[] {
        const searchStringLowerCase = searchString.toLowerCase();
        return options.filter(o => o.naam.toLowerCase().includes(searchStringLowerCase));
    }
}

export interface InputComboboxViewModel {
    onGetOpties: Observable<InputComboboxRow[]>;
    onGetSelected: Observable<InputComboboxRow>;

    doOnOptieSelected: (selection: InputComboboxRow) => void;
    customSearch?: (searchString: string, options: InputComboboxRow[]) => InputComboboxRow[];
}

export interface InputComboboxRow {
    naam: string;
    hasData: boolean;
}
