import { Component, OnDestroy, OnInit } from '@angular/core';
import { LeermiddelvakkoppelingNewFacade } from '../../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { Subscription } from 'rxjs';
import { PlatformLocation } from '@angular/common';
import { LeermiddelVakKoppelingInfo } from '../../../generated/graphql';
import { LeermiddelvakkoppelingVakkenComponentViewModel } from '../leermiddelvakkoppeling-vakken/leermiddelvakkoppeling-vakken.component';

@Component({
    selector: 'app-leermiddelvakkoppeling-new',
    templateUrl: './leermiddelvakkoppeling.component.html',
    styleUrls: ['./leermiddelvakkoppeling.component.scss']
})
export class LeermiddelvakkoppelingComponent implements OnInit, OnDestroy {

    verticalOffset = window.scrollY;
    dataZonderVak: LeermiddelVakKoppelingInfo[];
    dataMetVak: LeermiddelVakKoppelingInfo[];
    private subscriptions_: Subscription[] = [];
    toonGekoppeldeLeermiddelen = false;
    selectedLeermiddelVakKoppeling: LeermiddelVakKoppelingInfo;

    constructor(public facade: LeermiddelvakkoppelingNewFacade,
        location: PlatformLocation) {
        // closes modal when back button is clicked
        location.onPopState(() => this.closeVenster());
    }

    ngOnInit(): void {
    }

    public vakkenViewModel(): LeermiddelvakkoppelingVakkenComponentViewModel {
        return {
            storeLeermiddel: (selection) => this.facade.storeLeermiddel(selection)
        };
    }

    onScroll(): void {
        this.verticalOffset = window.scrollY;
    }

    closeVenster(): void {
        this.facade.setLeermiddelVakKoppelingOpen(false);
    }

    ngOnDestroy(): void {
        this.subscriptions_.forEach(s => s.unsubscribe());
    }
}
