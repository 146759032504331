import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AVGApiConfig, AVGApiService, AVGSemConsent, AVGSemPartyMarketplaceEntry, AVGSemPartyViewPageEntry } from 'src/generated/avg-client';

@Injectable()
export class AvgBackendService {
    private backendURL: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(private api: AVGApiService, private avgApiConfig: AVGApiConfig, private oauthService: OAuthService) {
        this.backendURL.pipe(distinctUntilChanged()).subscribe(url => this.avgApiConfig.rootUrl = url);
    }

    public getMarketplaceEntries(): Observable<AVGSemPartyMarketplaceEntry[]> {
        this.setSomtodayApiUrl();
        return this.api.getMarketplaceEntries();
    }

    public getPartyDetails(uuid: string): Observable<AVGSemPartyViewPageEntry> {
        this.setSomtodayApiUrl();
        return this.api.getPartyDetails({uuid});
    }

    public updateSemConsent(semPartyUuid: string, schoolId: string, consent: boolean): Observable<AVGSemConsent[]> {
        this.setSomtodayApiUrl();
        if (consent) {
            return this.api.setSemConsent({uuid: semPartyUuid, schoolId});
        }
        else {
            return this.api.unsetSemConsent({uuid: semPartyUuid, schoolId});
        }
    }

    private setSomtodayApiUrl(): void {
        this.backendURL.next(`${this.oauthService.getCustomTokenResponseProperty('somtoday_api_url')}/rest/v1`);
    }
}
