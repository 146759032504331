import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './components/button/button.component';
import { VakOnderwijsSwitchComponent } from './components/vak-onderwijs-switch/vak-onderwijs-switch.component';


/**
 *  De shared module is een module voor alle componenten, directives en pipes die in
 *  meerdere componenten worden gebruikt.
 *  In plaats van alle modules in elke feature module te importen worden ze hier allemaal
 *  geimport en geexport (zodat ze zichtbaar zijn voor andere modules). De featuremodules
 *  hoeven in dat geval alleen maar de sharedModule te importen.
 *
 *  Deze module mag geen providers bevatten!😠 Een lazy-loaded module dat dan deze shared module import
 *  maakt dan zijn eigen instantie van de service.
 *
 *  https://angular.io/guide/ngmodule#shared-modules
 *  https://angular.io/guide/ngmodule-faq#what-kinds-of-ngmodules-should-i-have-and-how-should-i-use-them
 */
@NgModule({
    declarations: [
        ButtonComponent,
        VakOnderwijsSwitchComponent
    ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    ButtonComponent,
    VakOnderwijsSwitchComponent
  ]
})
export class SharedModule { }
