import {createAction, props} from '@ngrx/store';
import {Payload} from '../payload';
import { Datumbereik, Vestiging } from './dashboardsettings.state';

export const setVestigingen = createAction('[DashboardSettings] Set vestigingen', props<Payload<Vestiging[]>>());
export const fetchDatumbereiken = createAction('[DashboardSettings] Fetch datumbereiken', props<Payload<Vestiging>>());
export const dashboardSettingsLoaded = createAction('[DashboardSettings] Reload dashboards');
export const toggleGroepering = createAction('[DashboardSettings] Toggle Groepering');
export const datumbereikenFetched = createAction('[DashboardSettings] Datumbereiken Fetched', props<Payload<Datumbereik[]>>());
export const setDatumbereik = createAction('[DashboardSettings] Set Datumbereik', props<Payload<Datumbereik>>());
export const resetBazenbanner = createAction('[DashboardSettings] Reset Bazenbanner');
