export { DashboardApiConfig } from './dashboard-api-config';
export { BaseService } from './base-service';
export { RequestBuilder } from './request-builder';
export { StrictHttpResponse } from './strict-http-response';
export { ApiModule } from './api.module';
export { DVestiging } from './models/d-vestiging';
export { DVestigingStatistieken } from './models/d-vestiging-statistieken';
export { DVestigingGebruik } from './models/d-vestiging-gebruik';
export { DSchoolDashboardItemHeader } from './models/d-school-dashboard-item-header';
export { DSchoolDashboardItemData } from './models/d-school-dashboard-item-data';
export { DSchoolDashboardLesgroepGebruik } from './models/d-school-dashboard-lesgroep-gebruik';
export { DVakDashboardData } from './models/d-vak-dashboard-data';
export { DVakDashboardRegel } from './models/d-vak-dashboard-regel';
export { DVakDashboardLesgroepGebruik } from './models/d-vak-dashboard-lesgroep-gebruik';
export { DLesgroepOverzichtDashboardData } from './models/d-lesgroep-overzicht-dashboard-data';
export { DLesgroepIndividueelGebruik } from './models/d-lesgroep-individueel-gebruik';
export { DLesgroepIndividueelGebruikLeerlingRegel } from './models/d-lesgroep-individueel-gebruik-leerling-regel';
export { DLesgroepIndividueelGebruikLeermiddelRegel } from './models/d-lesgroep-individueel-gebruik-leermiddel-regel';
export { DLesgroepSamenvattingDashboardData } from './models/d-lesgroep-samenvatting-dashboard-data';
export { DLesgroepGezamelijkGebruik } from './models/d-lesgroep-gezamelijk-gebruik';
export { DLesgroepSamenvattingGebruikGrafiek } from './models/d-lesgroep-samenvatting-gebruik-grafiek';
export { DLesgroepSamenvattingGebruik } from './models/d-lesgroep-samenvatting-gebruik';
export { DLesgroepSamenvattingGebruikTabel } from './models/d-lesgroep-samenvatting-gebruik-tabel';
export { DLesgroepSamenvattingGebruikTabelRegel } from './models/d-lesgroep-samenvatting-gebruik-tabel-regel';
export { DStudiewijzerDashboardPlatformData } from './models/d-studiewijzer-dashboard-platform-data';
export { DStudiewijzerDashboardVakData } from './models/d-studiewijzer-dashboard-vak-data';
export { DStudiewijzerDashboardItem } from './models/d-studiewijzer-dashboard-item';
export { DStudiewijzerLinkPlatformOfVakItem } from './models/d-studiewijzer-link-platform-of-vak-item';
export { DStudiewijzerDetails } from './models/d-studiewijzer-details';
export { DLeermiddel } from './models/d-leermiddel';
export { DVak } from './models/d-vak';
export { DDatumbereik } from './models/d-datumbereik';
export { DashboardApiService } from './services/dashboard-api.service';
