<div class="datepicker-wrapper">
    <div class="datepicker-link" (click)="toggleDatepicker()">
        <i class="icon-calender"></i>
        <p>Datumbereik</p>
    </div>
    <div class="datepicker-current">
        <div class="arrow-wrapper" (click)="navigaarNaarVorige()">
          <i class="arrow left {{(hasPrevious | async) ? 'enabled' : 'disabled'}}"></i>
        </div>
        <span
          [class.width-140]="(viewModel.onGetCurrentDatumbereik | async)?.periode === week"
          [class.width-90]="(viewModel.onGetCurrentDatumbereik | async)?.periode === maand"
          [class.width-60]="(viewModel.onGetCurrentDatumbereik | async)?.periode === schooljaar"
        >
          {{datumbereikFormatted$ | async}}
        </span>
        <div class="arrow-wrapper" (click)="navigaarNaarVolgende()">
          <i class="arrow right {{(hasNext | async) ? 'enabled' : 'disabled'}}"></i>
        </div>
    </div>
</div>

<div *ngIf="showDatepicker">
    <app-datumbereik-selectie-panel [viewModel]="datumbereikSelectieViewModel"></app-datumbereik-selectie-panel>
</div>
