/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { AVGApiConfig } from '../avg-api-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AVGSemConsent } from '../models/avg-sem-consent';
import { AVGSemPartyMarketplaceEntry } from '../models/avg-sem-party-marketplace-entry';
import { AVGSemPartyViewPageEntry } from '../models/avg-sem-party-view-page-entry';

@Injectable({
  providedIn: 'root',
})
export class AVGApiService extends BaseService {
  constructor(
    config: AVGApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMarketplaceEntries
   */
  static readonly GetMarketplaceEntriesPath = '/connect/avgdashboard/sem/marketplace';

  /**
   * Haal SEM koppelpartijen op. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarketplaceEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketplaceEntries$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AVGSemPartyMarketplaceEntry>>> {

    const rb = new RequestBuilder(this.rootUrl, AVGApiService.GetMarketplaceEntriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AVGSemPartyMarketplaceEntry>>;
      })
    );
  }

  /**
   * Haal SEM koppelpartijen op. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMarketplaceEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarketplaceEntries(params?: {
  }): Observable<Array<AVGSemPartyMarketplaceEntry>> {

    return this.getMarketplaceEntries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AVGSemPartyMarketplaceEntry>>) => r.body as Array<AVGSemPartyMarketplaceEntry>)
    );
  }

  /**
   * Path part for operation getPartyDetails
   */
  static readonly GetPartyDetailsPath = '/connect/avgdashboard/sem/party/{uuid}';

  /**
   * Haal details van een SEM koppelpartij op. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPartyDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartyDetails$Response(params: {
    uuid: string;
  }): Observable<StrictHttpResponse<AVGSemPartyViewPageEntry>> {

    const rb = new RequestBuilder(this.rootUrl, AVGApiService.GetPartyDetailsPath, 'get');
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AVGSemPartyViewPageEntry>;
      })
    );
  }

  /**
   * Haal details van een SEM koppelpartij op. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPartyDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPartyDetails(params: {
    uuid: string;
  }): Observable<AVGSemPartyViewPageEntry> {

    return this.getPartyDetails$Response(params).pipe(
      map((r: StrictHttpResponse<AVGSemPartyViewPageEntry>) => r.body as AVGSemPartyViewPageEntry)
    );
  }

  /**
   * Path part for operation setSemConsent
   */
  static readonly SetSemConsentPath = '/connect/avgdashboard/sem/party/{uuid}/consent/{schoolId}';

  /**
   * Geef consent voor de gegeven SEM koppelpartij. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setSemConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  setSemConsent$Response(params: {
    uuid: string;
    schoolId: string;
  }): Observable<StrictHttpResponse<Array<AVGSemConsent>>> {

    const rb = new RequestBuilder(this.rootUrl, AVGApiService.SetSemConsentPath, 'post');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.path('schoolId', params.schoolId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AVGSemConsent>>;
      })
    );
  }

  /**
   * Geef consent voor de gegeven SEM koppelpartij. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setSemConsent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setSemConsent(params: {
    uuid: string;
    schoolId: string;
  }): Observable<Array<AVGSemConsent>> {

    return this.setSemConsent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AVGSemConsent>>) => r.body as Array<AVGSemConsent>)
    );
  }

  /**
   * Path part for operation unsetSemConsent
   */
  static readonly UnsetSemConsentPath = '/connect/avgdashboard/sem/party/{uuid}/consent/{schoolId}';

  /**
   * Geef geen consent voor de gegeven SEM koppelpartij. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsetSemConsent()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsetSemConsent$Response(params: {
    uuid: string;
    schoolId: string;
  }): Observable<StrictHttpResponse<Array<AVGSemConsent>>> {

    const rb = new RequestBuilder(this.rootUrl, AVGApiService.UnsetSemConsentPath, 'delete');
    if (params) {
      rb.path('uuid', params.uuid, {});
      rb.path('schoolId', params.schoolId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AVGSemConsent>>;
      })
    );
  }

  /**
   * Geef geen consent voor de gegeven SEM koppelpartij. Zelfde rechten als koppelvlak, maar alleen beschikbaar als de SEM feature aan staat.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unsetSemConsent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsetSemConsent(params: {
    uuid: string;
    schoolId: string;
  }): Observable<Array<AVGSemConsent>> {

    return this.unsetSemConsent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AVGSemConsent>>) => r.body as Array<AVGSemConsent>)
    );
  }

}
