import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vak-onderwijs-switch-new',
  templateUrl: './vak-onderwijs-switch-new.component.html',
  styleUrls: ['./vak-onderwijs-switch-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakOnderwijsSwitchNewComponent implements OnInit {

  @Input()
  viewmodel: VakOnderwijsSwitchViewmodel;

  @Input()
  navigeerbaar: boolean;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public navigaarNaarVorige(): void {
    this.router.navigate([this.viewmodel.prevURL], {queryParamsHandling: 'preserve'});
  }

  public navigaarNaarVolgende(): void {
    this.router.navigate([this.viewmodel.nextURL], {queryParamsHandling: 'preserve'});
  }
}

export interface VakOnderwijsSwitchViewmodel {
  hoofdItem: string;
  subItem: string;
  prevURL: string;
  nextURL: string;
}
