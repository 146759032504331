<div class="grayout" (window:scroll)="onScroll()" [style.top]="verticalOffset + 'px'" (click)="closeVenster()">
</div>
<div class="uitlegoverlay" [style.top]="verticalOffset + 'px'">
    <div class="header">
        <i class="icon-koppelteken"></i>
        <span>Leermiddel-vak koppelingen</span>
        <a (click)="closeVenster()">
            <i class="icon-wit-kruis"></i>
        </a>
    </div>
    <div class="informatie">
        <i class="icon-uitroepteken"></i>
        <span>Persoonlijke licenties (ELF) kun je hier koppelen aan een vak,
            waarna ze getoond kunnen worden in de dashboards.</span>
    </div>

    <app-connect-loader [loading]="facade.isLoading() | async" [showText]="false">
        <div class="tabel">
            <div class="row">
                <div class="col icon-leerlingen"></div>
                <div class="col bodyContentSemi">Leermiddel</div>
                <div class="col bodyContentSemi">Vak</div>
            </div>
            <div class="row" *ngFor="let leermiddel of (facade.getLeermiddelenZonderVak() | async)">
                <div class="col"><span class="label">{{leermiddel.aantalLeerlingen}}</span></div>
                <div class="col bodyContent">{{leermiddel.naam}}</div>
                <div class="col right">
                    <app-leermiddelvakkoppeling-vakken [viewModel]="vakkenViewModel()" [leermiddel]=leermiddel>
                    </app-leermiddelvakkoppeling-vakken>
                </div>
            </div>
            <a *ngIf="(facade.getLeermiddelenMetVak() | async).length > 0"
                (click)="toonGekoppeldeLeermiddelen = !toonGekoppeldeLeermiddelen">
                <span>{{toonGekoppeldeLeermiddelen ? 'Verberg gekoppelde leermiddelen' : 'Toon gekoppelde leermiddelen'}}</span>
                <i [class]="toonGekoppeldeLeermiddelen ? 'icon-arrow-close' : 'icon-arrow-open'"></i>
            </a>
            <div *ngIf="toonGekoppeldeLeermiddelen">
                <div class="row" *ngFor="let leermiddel of (facade.getLeermiddelenMetVak() | async)">
                    <div class="col"><span class="label">{{leermiddel.aantalLeerlingen}}</span></div>
                    <div class="col bodyContent">{{leermiddel.naam}}</div>
                    <div class="col right">
                        <app-leermiddelvakkoppeling-vakken [viewModel]="vakkenViewModel()" [leermiddel]=leermiddel>
                        </app-leermiddelvakkoppeling-vakken>
                    </div>
                </div>
            </div>
            <!--TODO: lmv met vakken-->
        </div>
    </app-connect-loader>
</div>