import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, skip, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath, selectKoppelpartijParam, selectWebserviceParam} from '../state/router/router.selectors';
import {SomtodayService} from '../services/somtoday.service';
import {payload} from '../state/payload';
import {
  fetchGeselecteerdeKoppelingen,
  setGeselecteerdeKoppelpartij,
  setKoppelpartijen,
  setWebservices,
  setGeselecteerdeWebservice,
  setWebserviceAccountGebruik,
  setWebserviceWeekGebruik,
  deleteKoppeling,
  fetchKoppelpartijen,
  fetchGeselecteerdeKoppelpartij,
  setVestigingen,
  saveKoppeling,
  saveOpLeerlingGeautoriseerdeKoppeling,
  fetchSemParties,
  semPartiesFetched,
  fetchSemPartyDetails,
  semPartyDetailsFetched,
  semPartyConsentUpdated,
  updateSemPartyConsent
} from '../state/privacydashboard/privacydashboard.actions';
import {setGeselecteerdeKoppelingen} from '../state/privacydashboard/privacydashboard.actions';
import {merge, Observable, of} from 'rxjs';
import {ApolloError} from 'apollo-client';
import {reportError, setErrorMessages} from '../state/errors/errors.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {AppState, PRIVACY_DASHBOARD_FORBIDDEN} from '../state/app.state';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {
  selectGeselecteerdeWebservice,
  selectOudeWebservices,
  selectVestigingen
} from '../state/privacydashboard/privacydashboard.selectors';
import {selectKoppelpartijen} from '../state/privacydashboard/privacydashboard.selectors';
import {select, Store} from '@ngrx/store';
import moment from 'moment';
import { AvgBackendService } from '../services/avg-backend.service';

@Injectable()
export class PrivacydashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.pipe(select(selectKoppelpartijen))),
    filter(([{payload: p}, k]) => {
      const koppelpartijenAlreadyFound = k.length > 0;
      return getDashboardPath(p?.routerState?.url) === '/privacydashboard'
        && !p?.routerState?.root?.firstChild?.params?.koppelpartij
        && !koppelpartijenAlreadyFound;
    }),
    switchMap(() => this.somtodayService.fetchKoppelpartijen()),
    map(payload),
    map(setKoppelpartijen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {
  }
}

@Injectable()
export class RefreshPrivacydashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchKoppelpartijen),
    switchMap(() => this.somtodayService.fetchKoppelpartijen()),
    map(payload),
    map(setKoppelpartijen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService) {
  }
}

@Injectable()
export class VestigingenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.select(selectVestigingen)),
    filter(([{payload: p}, v]) => {
      return getDashboardPath(p?.routerState?.url) === '/privacydashboard' && !v;
    }),
    switchMap(() => {
      return this.somtodayService.fetchVestigingen();
    }),
    switchMap((vestigingen) => {
      return this.somtodayService.fetchVestigingInrichting(vestigingen.map(v => v.UUID));
    }),
    map(payload),
    map(setVestigingen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))));

  constructor(private router: Router, private actions$: Actions, private somtodayService: SomtodayService, private toastr: ToastrService, private store: Store<AppState>) {
  }
}

@Injectable()
export class RefreshGeslecteerdeKoppelpartijEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchGeselecteerdeKoppelpartij),
    switchMap(action => this.somtodayService.fetchKoppelpartij(action.koppelpartijUUID)),
    map(
      item => ({
        koppelpartij: item,
      })
    ),
    switchMap(i => [
      setGeselecteerdeKoppelpartij(i),
      fetchGeselecteerdeKoppelingen({
        koppelpartijUUID: i.koppelpartij.uuid,
        vestigingUUIDs: i.koppelpartij.koppelingen.map(koppeling => koppeling.vestiging.UUID),
      })
    ]),
  catchError(e => {
    if (e.status === 404) {
      // Link door naar privacy dashboard en geef error toaster. Negeer de error vervolgens.
      this.router.navigateByUrl('privacydashboard');
      this.toastr.error('Dit product is bij ons niet bekend.');
      return of(e).pipe(map(setErrorMessages));
    } else {
      return of(e).pipe(
        map((networkError: HttpErrorResponse) => networkError.status === 403 ? {
          networkError,
          errorMessage: PRIVACY_DASHBOARD_FORBIDDEN
        } : {networkError}),
        map(networkError => new ApolloError(networkError)),
        map(payload),
        map(reportError));
    }
  })));

  constructor(private router: Router, private actions$: Actions, private somtodayService: SomtodayService, private toastr: ToastrService) {
  }
}

@Injectable()
export class FetchGeslecteerdeKoppelingenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchGeselecteerdeKoppelingen),
    switchMap(action => this.somtodayService.fetchKoppeling(action.koppelpartijUUID, action.vestigingUUIDs)),
      map(
        item => ({
          koppelingen: item,
        })
      ),
      map(setGeselecteerdeKoppelingen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService) {}
}


@Injectable()
export class DeleteKoppeling {
  $ = createEffect(() => this.actions$.pipe(
    ofType(deleteKoppeling),
    switchMap((action) => {
      const observables: Observable<string>[] = [];
      if (action.vestigingen) {
        for (const v of action.vestigingen) {
          observables.push(this.somtodayService.deleteKoppeling(action.uuid, v));
        }
        return merge(...observables)
          .pipe(
            skip(action.vestigingen.length - 1),
            map((uuid) => ({uuid, aantal: action.vestigingen.length})),
        );
      } else {
        this.somtodayService.deleteKoppeling(action.uuid, null).pipe(map((uuid) => ({uuid, aantal: 0})));
      }
    }),
    tap((response) => {
      if (response.uuid) {
        this.toastr.info('De koppeling is succesvol verwijderd voor ' +
        (response.aantal === 0 ? 'alle' : response.aantal) +
        ' vestiging' +
        (response.aantal === 1 ? '.' : 'en.'));
      }
      else this.toastr.error('Er ging iets mis. De koppeling is nog niet verwijderd.');
    }),
    switchMap((response) => [
      fetchGeselecteerdeKoppelpartij({koppelpartijUUID: response.uuid}),
      fetchKoppelpartijen(),
    ]),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>, private toastr: ToastrService) {}
}

@Injectable()
export class SaveKoppeling {
  $ = createEffect(() => this.actions$.pipe(
    ofType(saveKoppeling),
    switchMap((action) => {
      const observables = [];
      for (const k of action.koppelingen) {
        observables.push(this.somtodayService.postKoppeling(k, action.koppelpartij.uuid));
      }
      return merge(...observables)
        .pipe(
          skip(action.koppelingen.length - 1),
          map((koppeling) => ({koppeling, koppelpartij: action.koppelpartij.naam, nieuw: action.nieuw})),
        );
    }),
    tap((succesvolAangemaakt) => {
        this.toastr.success('Gelukt! De koppeling met ' + succesvolAangemaakt.koppelpartij + ' is succesvol ' + (succesvolAangemaakt.nieuw ? 'aangemaakt.' : 'bewerkt.'));
    }),
    map(fetchKoppelpartijen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>, private toastr: ToastrService) {}
}

@Injectable()
export class SaveOpLeerlingGeautoriseerdeKoppeling {
  $ = createEffect(() => this.actions$.pipe(
    ofType(saveOpLeerlingGeautoriseerdeKoppeling),
    switchMap((action) => this.somtodayService.postOpLeerlingGeautoriseerdeKoppeling(action.koppeling, action.koppelpartij.uuid)
      .pipe(
        map(koppelingen => ({koppelingen, koppelpartij: action.koppelpartij.naam, nieuw: action.nieuw})),
      )),
    tap((succesvolAangemaakt) => {
      this.toastr.success('Gelukt! De koppeling met ' + succesvolAangemaakt.koppelpartij + ' is succesvol ' + (succesvolAangemaakt.nieuw ? 'aangemaakt.' : 'bewerkt.'));
    }),
    map(fetchKoppelpartijen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>, private toastr: ToastrService) {}
}

@Injectable()
export class FetchWebservicesEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.pipe(select(selectOudeWebservices)), this.store.select(selectWebserviceParam)),
    filter(([{payload: p}, ws, wsp]) => {
      const webservicesAlreadyFound = (ws != null);
      const webserviceParamFound = (wsp != null);
      return p?.routerState?.url.includes('/privacydashboard/datatoegang') && !webservicesAlreadyFound && !webserviceParamFound;
    }),
    switchMap(() => this.somtodayService.fetchWebservices()),
    map(payload),
    map(setWebservices),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchGeselecteerdeWebserviceEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.pipe(select(selectGeselecteerdeWebservice)), this.store.select(selectWebserviceParam)),
    filter(([{payload: p}, gws, wsp]) => {
      const webserviceAlreadyFound = (gws != null);
      const webserviceParamFound = (wsp != null);
      return p?.routerState?.url.includes('/privacydashboard/datatoegang') && !webserviceAlreadyFound && webserviceParamFound;
    }),
    switchMap(() => this.somtodayService.fetchWebservices()),
    map(payload),
    withLatestFrom(this.store.select(selectWebserviceParam)),
    map(([{value}, wsp]) => {
      return setGeselecteerdeWebservice({webservice: value.find(ws => ws.service.toLowerCase() === wsp)});
    }),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchWebserviceAccountGebruikEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.select(selectWebserviceParam), this.store.select(selectGeselecteerdeWebservice)),
    filter(([{payload: p}, wsp, gws]) => {
      const webserviceParamFound = (wsp != null);
      const webserviceAccountGebruikFound = gws?.accountGebruik;
      return p?.routerState?.url.includes('/privacydashboard/datatoegang') && webserviceParamFound && !webserviceAccountGebruikFound;
    }),
    switchMap(([, wsp, ]) => this.somtodayService.fetchWebserviceAccountGebruik(wsp.toUpperCase())),
    map(payload),
    map(setWebserviceAccountGebruik),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class FetchWebserviceWeekGebruikEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.select(selectWebserviceParam), this.store.select(selectGeselecteerdeWebservice)),
    filter(([{payload: p}, wsp, gws]) => {
      const webserviceParamFound = (wsp != null);
      const webserviceWeekGebruikFound = gws?.weekGebruik;
      return p?.routerState?.url.includes('/privacydashboard/datatoegang') && webserviceParamFound && !webserviceWeekGebruikFound;
    }),
    switchMap(([, wsp, ]) => this.somtodayService.fetchWebserviceWeekGebruik(wsp.toUpperCase())),
    map(datapoints => datapoints.sort((a, b) => moment(a.eersteDagWeek).toDate().getTime() - moment(b.eersteDagWeek).toDate().getTime())),
    map(payload),
    map(setWebserviceWeekGebruik),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>) {}
}

@Injectable()
export class SemEffects {
    constructor(private actions: Actions, private avgBackend: AvgBackendService){}

    onFetchSemParties = createEffect(() => this.actions.pipe(
        ofType(fetchSemParties),
        switchMap(() => this.avgBackend.getMarketplaceEntries().pipe(
            take(1),
            map(fetchedEntries => semPartiesFetched({semParties: fetchedEntries}))
        ))
    ));

    onFetchSemPartyDetails = createEffect(() => this.actions.pipe(
        ofType(fetchSemPartyDetails),
        switchMap(({semPartyUUID}) => this.avgBackend.getPartyDetails(semPartyUUID).pipe(
            take(1),
            map(fetchedDetails => semPartyDetailsFetched({semPartyDetails: fetchedDetails})),
            catchError(error => of(error).pipe(map(payload), map(reportError)))
        ))
    ));

    onUpdateSemPartyConsent = createEffect(() => this.actions.pipe(
        ofType(updateSemPartyConsent),
        switchMap(({semPartyUUID, digiDeliveryId, consent}) => this.avgBackend.updateSemConsent(semPartyUUID, digiDeliveryId, consent).pipe(
            take(1),
            map(response => semPartyConsentUpdated({consent: response})),
            catchError(error => of(error).pipe(map(payload), map(reportError)))
        ))
    ));
}
