/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { DashboardApiConfig } from '../dashboard-api-config';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DDatumbereik } from '../models/d-datumbereik';
import { DLeermiddel } from '../models/d-leermiddel';
import { DLesgroepOverzichtDashboardData } from '../models/d-lesgroep-overzicht-dashboard-data';
import { DLesgroepSamenvattingDashboardData } from '../models/d-lesgroep-samenvatting-dashboard-data';
import { DSchoolDashboardItemData } from '../models/d-school-dashboard-item-data';
import { DSchoolDashboardItemHeader } from '../models/d-school-dashboard-item-header';
import { DStudiewijzerDashboardPlatformData } from '../models/d-studiewijzer-dashboard-platform-data';
import { DStudiewijzerDashboardVakData } from '../models/d-studiewijzer-dashboard-vak-data';
import { DStudiewijzerDetails } from '../models/d-studiewijzer-details';
import { DStudiewijzerLinkPlatformOfVakItem } from '../models/d-studiewijzer-link-platform-of-vak-item';
import { DVakDashboardData } from '../models/d-vak-dashboard-data';
import { DVestiging } from '../models/d-vestiging';
import { DVestigingStatistieken } from '../models/d-vestiging-statistieken';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService extends BaseService {
  constructor(
    config: DashboardApiConfig,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getVestigingen
   */
  static readonly GetVestigingenPath = '/connect/dashboard/3.0/vestiging';

  /**
   * Levert voor geauthenticeerde gebruiker beschikbare vestigingen op.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVestigingen()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVestigingen$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DVestiging>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetVestigingenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DVestiging>>;
      })
    );
  }

  /**
   * Levert voor geauthenticeerde gebruiker beschikbare vestigingen op.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVestigingen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVestigingen(params?: {
  }): Observable<Array<DVestiging>> {

    return this.getVestigingen$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DVestiging>>) => r.body as Array<DVestiging>)
    );
  }

  /**
   * Path part for operation getDatumbereiken
   */
  static readonly GetDatumbereikenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/datumbereik';

  /**
   * Levert voor geauthenticeerde gebruiker beschikbare datumbereiken op.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDatumbereiken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatumbereiken$Response(params: {

    /**
     * UUID van vestiging waarvoor datumbereiken moeten worden opgevraagd.
     */
    vestigingUUID: string;
  }): Observable<StrictHttpResponse<Array<DDatumbereik>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetDatumbereikenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DDatumbereik>>;
      })
    );
  }

  /**
   * Levert voor geauthenticeerde gebruiker beschikbare datumbereiken op.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDatumbereiken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDatumbereiken(params: {

    /**
     * UUID van vestiging waarvoor datumbereiken moeten worden opgevraagd.
     */
    vestigingUUID: string;
  }): Observable<Array<DDatumbereik>> {

    return this.getDatumbereiken$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DDatumbereik>>) => r.body as Array<DDatumbereik>)
    );
  }

  /**
   * Path part for operation getVestigingStatistieken
   */
  static readonly GetVestigingStatistiekenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/statistieken';

  /**
   * Bazenbanner stats voor de desbetreffende Vestiging.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVestigingStatistieken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVestigingStatistieken$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DVestigingStatistieken>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetVestigingStatistiekenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DVestigingStatistieken>;
      })
    );
  }

  /**
   * Bazenbanner stats voor de desbetreffende Vestiging.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVestigingStatistieken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVestigingStatistieken(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DVestigingStatistieken> {

    return this.getVestigingStatistieken$Response(params).pipe(
      map((r: StrictHttpResponse<DVestigingStatistieken>) => r.body as DVestigingStatistieken)
    );
  }

  /**
   * Path part for operation getSchooldashboardVakken
   */
  static readonly GetSchooldashboardVakkenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/vak';

  /**
   * Schooldashboard tabellen voor de desbetreffende Vestiging gegroepeerd op Vak
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchooldashboardVakken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardVakken$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DSchoolDashboardItemHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetSchooldashboardVakkenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DSchoolDashboardItemHeader>>;
      })
    );
  }

  /**
   * Schooldashboard tabellen voor de desbetreffende Vestiging gegroepeerd op Vak
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSchooldashboardVakken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardVakken(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DSchoolDashboardItemHeader>> {

    return this.getSchooldashboardVakken$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DSchoolDashboardItemHeader>>) => r.body as Array<DSchoolDashboardItemHeader>)
    );
  }

  /**
   * Path part for operation getSchooldashboardRegelsVoorVak
   */
  static readonly GetSchooldashboardRegelsVoorVakPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/vak/{vakNaam}/details';

  /**
   * Schooldashboard tabelregels voor één Vestiging en één Vak
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchooldashboardRegelsVoorVak()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardRegelsVoorVak$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Vak waarover detailregels opgeleverd moeten worden.
     */
    vakNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DSchoolDashboardItemData>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetSchooldashboardRegelsVoorVakPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('vakNaam', params.vakNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DSchoolDashboardItemData>>;
      })
    );
  }

  /**
   * Schooldashboard tabelregels voor één Vestiging en één Vak
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSchooldashboardRegelsVoorVak$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardRegelsVoorVak(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Vak waarover detailregels opgeleverd moeten worden.
     */
    vakNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DSchoolDashboardItemData>> {

    return this.getSchooldashboardRegelsVoorVak$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DSchoolDashboardItemData>>) => r.body as Array<DSchoolDashboardItemData>)
    );
  }

  /**
   * Path part for operation getSchooldashboardOnderwijssoorten
   */
  static readonly GetSchooldashboardOnderwijssoortenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/onderwijssoort';

  /**
   * Schooldashboard tabellen voor de desbetreffende Vestiging gegroepeerd op Onderwijssoort
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchooldashboardOnderwijssoorten()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardOnderwijssoorten$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DSchoolDashboardItemHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetSchooldashboardOnderwijssoortenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DSchoolDashboardItemHeader>>;
      })
    );
  }

  /**
   * Schooldashboard tabellen voor de desbetreffende Vestiging gegroepeerd op Onderwijssoort
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSchooldashboardOnderwijssoorten$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardOnderwijssoorten(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DSchoolDashboardItemHeader>> {

    return this.getSchooldashboardOnderwijssoorten$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DSchoolDashboardItemHeader>>) => r.body as Array<DSchoolDashboardItemHeader>)
    );
  }

  /**
   * Path part for operation getSchooldashboardRegelsVoorOnderwijssoort
   */
  static readonly GetSchooldashboardRegelsVoorOnderwijssoortPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/onderwijssoort/{onderwijssoortNaam}/details/';

  /**
   * Schooldashboard tabelregels voor één Vestiging en één Onderwijssoort
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSchooldashboardRegelsVoorOnderwijssoort()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardRegelsVoorOnderwijssoort$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Onderwijssoort waarover detailregels opgeleverd moeten worden.
     */
    onderwijssoortNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DSchoolDashboardItemData>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetSchooldashboardRegelsVoorOnderwijssoortPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('onderwijssoortNaam', params.onderwijssoortNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DSchoolDashboardItemData>>;
      })
    );
  }

  /**
   * Schooldashboard tabelregels voor één Vestiging en één Onderwijssoort
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSchooldashboardRegelsVoorOnderwijssoort$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSchooldashboardRegelsVoorOnderwijssoort(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Onderwijssoort waarover detailregels opgeleverd moeten worden.
     */
    onderwijssoortNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DSchoolDashboardItemData>> {

    return this.getSchooldashboardRegelsVoorOnderwijssoort$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DSchoolDashboardItemData>>) => r.body as Array<DSchoolDashboardItemData>)
    );
  }

  /**
   * Path part for operation getVakdashboardData
   */
  static readonly GetVakdashboardDataPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/onderwijssoort/{onderwijssoortNaam}/vak/{vakNaam}/details/';

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVakdashboardData()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVakdashboardData$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Onderwijssoort waarover detailregels opgeleverd moeten worden.
     */
    onderwijssoortNaam: string;

    /**
     * Vak waarover detailregels opgeleverd moeten worden.
     */
    vakNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DVakDashboardData>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetVakdashboardDataPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('onderwijssoortNaam', params.onderwijssoortNaam, {});
      rb.path('vakNaam', params.vakNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DVakDashboardData>;
      })
    );
  }

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVakdashboardData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVakdashboardData(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Onderwijssoort waarover detailregels opgeleverd moeten worden.
     */
    onderwijssoortNaam: string;

    /**
     * Vak waarover detailregels opgeleverd moeten worden.
     */
    vakNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DVakDashboardData> {

    return this.getVakdashboardData$Response(params).pipe(
      map((r: StrictHttpResponse<DVakDashboardData>) => r.body as DVakDashboardData)
    );
  }

  /**
   * Path part for operation getLesgroepOverzicht
   */
  static readonly GetLesgroepOverzichtPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/lesgroep/{lesgroepUUID}/overzicht';

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLesgroepOverzicht()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLesgroepOverzicht$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * UUID van lesgroep voor dashboard.
     */
    lesgroepUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DLesgroepOverzichtDashboardData>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetLesgroepOverzichtPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('lesgroepUUID', params.lesgroepUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DLesgroepOverzichtDashboardData>;
      })
    );
  }

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLesgroepOverzicht$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLesgroepOverzicht(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * UUID van lesgroep voor dashboard.
     */
    lesgroepUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DLesgroepOverzichtDashboardData> {

    return this.getLesgroepOverzicht$Response(params).pipe(
      map((r: StrictHttpResponse<DLesgroepOverzichtDashboardData>) => r.body as DLesgroepOverzichtDashboardData)
    );
  }

  /**
   * Path part for operation getLesgroepSamenvatting
   */
  static readonly GetLesgroepSamenvattingPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/licenties/lesgroep/{lesgroepUUID}/samenvatting';

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLesgroepSamenvatting()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLesgroepSamenvatting$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * UUID van lesgroep voor dashboard.
     */
    lesgroepUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DLesgroepSamenvattingDashboardData>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetLesgroepSamenvattingPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('lesgroepUUID', params.lesgroepUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DLesgroepSamenvattingDashboardData>;
      })
    );
  }

  /**
   * vakdashboard data opvragen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLesgroepSamenvatting$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLesgroepSamenvatting(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * UUID van lesgroep voor dashboard.
     */
    lesgroepUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DLesgroepSamenvattingDashboardData> {

    return this.getLesgroepSamenvatting$Response(params).pipe(
      map((r: StrictHttpResponse<DLesgroepSamenvattingDashboardData>) => r.body as DLesgroepSamenvattingDashboardData)
    );
  }

  /**
   * Path part for operation getStudiewijzerVakken
   */
  static readonly GetStudiewijzerVakkenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/vak';

  /**
   * studiewijzer data opvragen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStudiewijzerVakken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerVakken$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DStudiewijzerDashboardVakData>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetStudiewijzerVakkenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DStudiewijzerDashboardVakData>;
      })
    );
  }

  /**
   * studiewijzer data opvragen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStudiewijzerVakken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerVakken(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DStudiewijzerDashboardVakData> {

    return this.getStudiewijzerVakken$Response(params).pipe(
      map((r: StrictHttpResponse<DStudiewijzerDashboardVakData>) => r.body as DStudiewijzerDashboardVakData)
    );
  }

  /**
   * Path part for operation getStudiewijzerPlatformen
   */
  static readonly GetStudiewijzerPlatformenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform';

  /**
   * studiewijzer data opvragen
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStudiewijzerPlatformen()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerPlatformen$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<DStudiewijzerDashboardPlatformData>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetStudiewijzerPlatformenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DStudiewijzerDashboardPlatformData>;
      })
    );
  }

  /**
   * studiewijzer data opvragen
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStudiewijzerPlatformen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerPlatformen(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<DStudiewijzerDashboardPlatformData> {

    return this.getStudiewijzerPlatformen$Response(params).pipe(
      map((r: StrictHttpResponse<DStudiewijzerDashboardPlatformData>) => r.body as DStudiewijzerDashboardPlatformData)
    );
  }

  /**
   * Path part for operation getStudiewijzerVaksectiePlatformen
   */
  static readonly GetStudiewijzerVaksectiePlatformenPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/vaksectie/{vaksectieNaam}/platform';

  /**
   * stiediewijzer data voor specifiek platform
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStudiewijzerVaksectiePlatformen()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerVaksectiePlatformen$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van de vaksectie waarvoor de platformen opgevraagd worden.
     */
    vaksectieNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetStudiewijzerVaksectiePlatformenPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('vaksectieNaam', params.vaksectieNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>;
      })
    );
  }

  /**
   * stiediewijzer data voor specifiek platform
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStudiewijzerVaksectiePlatformen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerVaksectiePlatformen(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van de vaksectie waarvoor de platformen opgevraagd worden.
     */
    vaksectieNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DStudiewijzerLinkPlatformOfVakItem>> {

    return this.getStudiewijzerVaksectiePlatformen$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>) => r.body as Array<DStudiewijzerLinkPlatformOfVakItem>)
    );
  }

  /**
   * Path part for operation getStudiewijzerPlatformVaksecties
   */
  static readonly GetStudiewijzerPlatformVaksectiesPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform/{platformNaam}/vaksectie';

  /**
   * studiewijzer voor bepaalde vaksectie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStudiewijzerPlatformVaksecties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerPlatformVaksecties$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van het platform waarvoor de vaksecties opgevraagd worden.
     */
    platformNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetStudiewijzerPlatformVaksectiesPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('platformNaam', params.platformNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>;
      })
    );
  }

  /**
   * studiewijzer voor bepaalde vaksectie
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStudiewijzerPlatformVaksecties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerPlatformVaksecties(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van het platform waarvoor de vaksecties opgevraagd worden.
     */
    platformNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DStudiewijzerLinkPlatformOfVakItem>> {

    return this.getStudiewijzerPlatformVaksecties$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DStudiewijzerLinkPlatformOfVakItem>>) => r.body as Array<DStudiewijzerLinkPlatformOfVakItem>)
    );
  }

  /**
   * Path part for operation getStudiewijzerDetails
   */
  static readonly GetStudiewijzerDetailsPath = '/connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform/{platformNaam}/vaksectie/{vaksectieNaam}';

  /**
   * studiewijzerdata voor platform/vaksectie combo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStudiewijzerDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerDetails$Response(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van het platform voor op te vragen details
     */
    platformNaam: string;

    /**
     * Naam van de vaksectie voor op te vragen details
     */
    vaksectieNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<StrictHttpResponse<Array<DStudiewijzerDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetStudiewijzerDetailsPath, 'get');
    if (params) {
      rb.path('vestigingUUID', params.vestigingUUID, {});
      rb.path('platformNaam', params.platformNaam, {});
      rb.path('vaksectieNaam', params.vaksectieNaam, {});
      rb.query('begindatum', params.begindatum, {});
      rb.query('periode', params.periode, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DStudiewijzerDetails>>;
      })
    );
  }

  /**
   * studiewijzerdata voor platform/vaksectie combo
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStudiewijzerDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStudiewijzerDetails(params: {

    /**
     * UUID van vestiging waarvoor stats moeten worden opgevraagd.
     */
    vestigingUUID: string;

    /**
     * Naam van het platform voor op te vragen details
     */
    platformNaam: string;

    /**
     * Naam van de vaksectie voor op te vragen details
     */
    vaksectieNaam: string;

    /**
     * Startdatum van de op te leveren data.
     */
    begindatum: string;

    /**
     * Op te leveren periode.
     */
    periode: 'WEEK' | 'MAAND' | 'JAAR';
  }): Observable<Array<DStudiewijzerDetails>> {

    return this.getStudiewijzerDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DStudiewijzerDetails>>) => r.body as Array<DStudiewijzerDetails>)
    );
  }

  /**
   * Path part for operation getLeermiddelenVoorMedewerkerVestigingen
   */
  static readonly GetLeermiddelenVoorMedewerkerVestigingenPath = '/connect/dashboard/3.0/leermiddel';

  /**
   * alle leermiddelen voor de Vestigingen van de ingelogde Medewerker
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLeermiddelenVoorMedewerkerVestigingen()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeermiddelenVoorMedewerkerVestigingen$Response(params?: {
  }): Observable<StrictHttpResponse<Array<DLeermiddel>>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.GetLeermiddelenVoorMedewerkerVestigingenPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DLeermiddel>>;
      })
    );
  }

  /**
   * alle leermiddelen voor de Vestigingen van de ingelogde Medewerker
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLeermiddelenVoorMedewerkerVestigingen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLeermiddelenVoorMedewerkerVestigingen(params?: {
  }): Observable<Array<DLeermiddel>> {

    return this.getLeermiddelenVoorMedewerkerVestigingen$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DLeermiddel>>) => r.body as Array<DLeermiddel>)
    );
  }

  /**
   * Path part for operation postLeermiddel
   */
  static readonly PostLeermiddelPath = '/connect/dashboard/3.0/leermiddel/{leermiddelUUID}';

  /**
   * Sla LeermiddelVakKoppeling op - alleen als Medewerker toegang heeft tot gekoppelde Leermiddel en Vak. Alternatief voor /connect/dashboard/3.0/leermiddel/{leermiddelUUID}/vak/{vakUUID} waarbij meerdere koppelingen kunnen worden aangemaakt voor één leermiddel.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postLeermiddel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLeermiddel$Response(params: {

    /**
     * UUID het leermiddel dat gekoppeld moet worden.
     */
    leermiddelUUID: string;
    body?: DLeermiddel
  }): Observable<StrictHttpResponse<DLeermiddel>> {

    const rb = new RequestBuilder(this.rootUrl, DashboardApiService.PostLeermiddelPath, 'post');
    if (params) {
      rb.path('leermiddelUUID', params.leermiddelUUID, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DLeermiddel>;
      })
    );
  }

  /**
   * Sla LeermiddelVakKoppeling op - alleen als Medewerker toegang heeft tot gekoppelde Leermiddel en Vak. Alternatief voor /connect/dashboard/3.0/leermiddel/{leermiddelUUID}/vak/{vakUUID} waarbij meerdere koppelingen kunnen worden aangemaakt voor één leermiddel.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `postLeermiddel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postLeermiddel(params: {

    /**
     * UUID het leermiddel dat gekoppeld moet worden.
     */
    leermiddelUUID: string;
    body?: DLeermiddel
  }): Observable<DLeermiddel> {

    return this.postLeermiddel$Response(params).pipe(
      map((r: StrictHttpResponse<DLeermiddel>) => r.body as DLeermiddel)
    );
  }

}
