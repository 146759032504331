import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {
  Bazenbanner,
  SchooldashboardItemContent,
  SchooldashboardItemHeader
} from '../state/schooldashboard/schooldashboard.state';
import {
  KlassendashboardState
} from '../state/klassendashboard/klassendashboard.state';
import {LesgroepInformatieRegel, TotaalInformatieRegel} from '../state/vakdashboard/vakdashboard.state';
import {
  OnderwijssoortLeerjaarInzicht,
  StudiewijzerlinkjesState, StudiewijzerPlatformOfVakItem
} from '../state/studiewijzerlinkjes/studiewijzerlinkjes.state';
import {DashboardApiService} from 'src/generated/dashboard-client';
import { map } from 'rxjs/internal/operators/map';
import { Leermiddel } from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.state';
import { Datumbereik, PERIODE, Vestiging } from '../state/dashboardsettings/dashboardsettings.state';

@Injectable()
export class DashboardingService {

  constructor(
    private dashboardApi: DashboardApiService
  ) {
  }

  public fetchVestigingen(): Observable<Vestiging[]> {
    return this.dashboardApi.getVestigingen().pipe(map(vestigingen => vestigingen.map(vestiging => {
      return {
        uuid: vestiging.UUID,
        naam: vestiging.naam,
        heeftData: vestiging.heeftData
      };
    })));
  }

  public fetchSchooldashboardVakken(begindatum: string, periode: PERIODE, vestigingUUID: string): Observable<SchooldashboardItemHeader[]> {
    return this.dashboardApi.getSchooldashboardVakken({vestigingUUID, begindatum, periode}).pipe(map(vakken => vakken.map(vak => {
      return {
        naam: vak.naam,
        actieveLeerlingen: vak.actieveLeerlingen,
        gebruikPerWeek: vak.gebruikPerWeek,
      };
    })));
  }

  public fetchSchooldashboardVakDetails(begindatum: string, periode: PERIODE, vestigingUUID: string, vakNaam: string): Observable<SchooldashboardItemContent[]> {
    return this.dashboardApi.getSchooldashboardRegelsVoorVak({vestigingUUID, vakNaam, begindatum, periode}).pipe(map(vakdetails => vakdetails.map(detail => {
      return {
        naam: detail.naam,
        aantalLesgroepen: detail.aantalLesgroepen,
        activatiePercentage: detail.activatiePercentage,
        laagsteActivatie: detail.laagsteActivatie,
        hoogsteActivatie: detail.hoogsteActivatie,
        gemiddeldPercentageActief: detail.gemiddeldPercentageActief,
        laagstePercentageActief: detail.laagstePercentageActief,
        hoogstePercentageActief: detail.hoogstePercentageActief,
        leerlingGebruikBereik: detail.leerlingGebruikBereik.map(lgb => ({
          key: lgb.key,
          minimaalLesgroepGebruik: lgb.minimaalLesgroepGebruik,
          gemiddeldLesgroepGebruik: lgb.gemiddeldLesgroepGebruik,
          maximaalLesgroepGebruik: lgb.maximaalLesgroepGebruik,
        })),
        gemiddeldGebruik: detail.gemiddeldGebruik
      };
    })));
  }

  public fetchSchooldashboardOnderwijssoorten(begindatum: string, periode: PERIODE, vestigingUUID: string): Observable<SchooldashboardItemHeader[]> {
    return this.dashboardApi.getSchooldashboardOnderwijssoorten({vestigingUUID, begindatum, periode}).pipe(map(onderwijssoorten => onderwijssoorten.map(os => {
      return {
        naam: os.naam,
        actieveLeerlingen: os.actieveLeerlingen,
        gebruikPerWeek: os.gebruikPerWeek,
      };
    })));
  }

  public fetchSchooldashboardOnderwijssoortDetails(begindatum: string, periode: PERIODE, vestigingUUID: string, onderwijssoortNaam: string): Observable<SchooldashboardItemContent[]> {
    return this.dashboardApi.getSchooldashboardRegelsVoorOnderwijssoort({vestigingUUID, onderwijssoortNaam, begindatum, periode}).pipe(map(osdetails => osdetails.map(detail => {
      return {
        naam: detail.naam,
        aantalLesgroepen: detail.aantalLesgroepen,
        activatiePercentage: detail.activatiePercentage,
        laagsteActivatie: detail.laagsteActivatie,
        hoogsteActivatie: detail.hoogsteActivatie,
        gemiddeldPercentageActief: detail.gemiddeldPercentageActief,
        laagstePercentageActief: detail.laagstePercentageActief,
        hoogstePercentageActief: detail.hoogstePercentageActief,
        leerlingGebruikBereik: detail.leerlingGebruikBereik.map(lgb => ({
          key: lgb.key,
          minimaalLesgroepGebruik: lgb.minimaalLesgroepGebruik,
          gemiddeldLesgroepGebruik: lgb.gemiddeldLesgroepGebruik,
          maximaalLesgroepGebruik: lgb.maximaalLesgroepGebruik,
        })),
        gemiddeldGebruik: detail.gemiddeldGebruik,
      };
    })));
  }

  public fetchBazenbanner(begindatum: string, periode: PERIODE, vestigingUUID: string): Observable<Bazenbanner> {
    return this.dashboardApi.getVestigingStatistieken({vestigingUUID, begindatum, periode}).pipe(map(vestigingStatistieken => {
      return {
        aantalLeerlingen: vestigingStatistieken.aantalLeerlingen,
        leermiddelGebruik: vestigingStatistieken.leermiddelGebruik.map(lg => ({
          datum: lg.datum,
          gebruik: lg.gebruik
        })),
        activatieGraad: vestigingStatistieken.activatieGraad,
        licentiesPerLeerling: vestigingStatistieken.licentiesPerLeerling,
        geactiveerdeLicenties: vestigingStatistieken.geactiveerdeLicenties,
        educatieveAanbieders: vestigingStatistieken.educatieveAanbieders,
        gemiddeldGebruik: vestigingStatistieken.gemiddeldGebruik,
      };
    }));
  }

  public fetchVakdashboard(begindatum: string, periode: PERIODE, vestigingUUID: string, onderwijssoortNaam: string, vakNaam: string): Observable<{ regels: LesgroepInformatieRegel[], totaal: TotaalInformatieRegel }> {
    return this.dashboardApi.getVakdashboardData({vestigingUUID, onderwijssoortNaam, vakNaam, begindatum, periode}).pipe(map(vakdashboard  => {
      return {
         regels: vakdashboard.regels.map(regel => ({
           aantalGeactiveerd: regel.aantalGeactiveerd,
           aantalLeerlingen: regel.aantalLeerlingen,
           aantalNietGeactiveerd: regel.aantalNietGeactiveerd,
           aantalRecentActief: regel.aantalRecentActief,
           aantalRecentInactief: regel.aantalRecentInactief,
           docentUUIDs: regel.docentUUIDs,
           docentNamen: undefined, // Wordt later gevuld adhv docentUUIDs
           lesgroepGebruik: regel.lesgroepGebruik.map(lesgroepGebruik => ({
             key: lesgroepGebruik.key,
             gebruik: lesgroepGebruik.gebruik,
           })),
           lesgroepNaam: regel.lesgroepNaam,
           lesgroepUUID: regel.lesgroepUUID,
           percentageGeactiveerd: regel.percentageGeactiveerd,
           recentActiefVanGeactiveerd: regel.recentActiefVanGeactiveerd,
           gemiddeldGebruik: regel.gemiddeldGebruik,
         })),
         totaal: {
            aantalLeerlingen: vakdashboard.aantalLeerlingen,
            activatiePercentage: vakdashboard.activatiePercentage,
            laagsteActivatie: vakdashboard.laagsteActivatie,
            hoogsteActivatie: vakdashboard.hoogsteActivatie,
            gemiddeldPercentageActief: vakdashboard.gemiddeldPercentageActief,
            laagstePercentageActief: vakdashboard.laagstePercentageActief,
            hoogstePercentageActief: vakdashboard.hoogstePercentageActief,
            totaalGebruik: vakdashboard.leerlingGebruikBereik.map(gebruik => ({
                key: gebruik.key,
                minimaalLeerlingGebruik: gebruik.minimaalLesgroepGebruik,
                gemiddeldLeerlingGebruik: gebruik.gemiddeldLesgroepGebruik,
                maximaalLeerlingGebruik: gebruik.maximaalLesgroepGebruik
            })),
            gemiddeldGebruik: vakdashboard.gemiddeldGebruik
         }
      };
    }));
  }

  public fetchKlassendashboardIndividueel(begindatum: string, periode: PERIODE, vestigingUUID: string, lgUUID: string): Observable<KlassendashboardState> {
    return this.dashboardApi.getLesgroepOverzicht({vestigingUUID, lesgroepUUID: lgUUID, begindatum, periode}).pipe(map(lesgroepOverzicht => {
      return {
        lesgroepUUID: lgUUID,
        lesgroepNaam: lesgroepOverzicht.lesgroepNaam,
        individueelGebruik: lesgroepOverzicht.individueelGebruik,
        gezamenlijkGebruik: undefined,
      };
  }));
  }

  public fetchKlassendashboardSamenvatting(beginDatum: string, periode: PERIODE, vestigingUUID: string, lesgroepUUID: string): Observable<KlassendashboardState> {
    return this.dashboardApi.getLesgroepSamenvatting({vestigingUUID, lesgroepUUID, begindatum: beginDatum, periode})
      .pipe(map(ls => {
          if (ls !== null) {
              return {
                  lesgroepUUID,
                  lesgroepNaam: ls.lesgroepNaam,
                  gezamenlijkGebruik: ls.gezamelijkGebruik,
                  individueelGebruik: undefined
              };
          }
          return {
              lesgroepUUID,
              lesgroepNaam: '',
              gezamenlijkGebruik: null,
              individueelGebruik: undefined
          };
      }));
  }

  public fetchStudiewijzerlinkjesPlatform(beginDatum: string, periode: string, vestigingUUID: string): Observable<StudiewijzerlinkjesState> {
    // TODO haal data op via endpoint /connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform
    return new Observable<StudiewijzerlinkjesState>();
  }

  public fetchStudiewijzerlinkjesVak(beginDatum: string, periode: string, vestigingUUID: string): Observable<StudiewijzerlinkjesState> {
    // TODO haal data op via endpoint /connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/vak
    return new Observable<StudiewijzerlinkjesState>();
  }

  public fetchStudiewijzerVakDetails(beginDatum: string, periode: string, vestigingUUID: string, vaksectieNaam: string): Observable<StudiewijzerPlatformOfVakItem[]> {
    // TODO haal data op via endpoint /connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/vaksectie/{vaksectieNaam}/platform
    return new Observable<StudiewijzerPlatformOfVakItem[]>();
  }

  public fetchStudiewijzerPlatformDetails(beginDatum: string, periode: string, vestigingUUID: string, platformNaam: string): Observable<StudiewijzerPlatformOfVakItem[]> {
    // TODO haal data op via endpoint /connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform/{platformNaam}/vaksectie
    return new Observable<StudiewijzerPlatformOfVakItem[]>();
  }

  public fetchOnderwijssoortLeerjaarInzicht(beginDatum: string, periode: string, vestigingUUID: string, platformUUID: string, vaksectieNaam: string): Observable<OnderwijssoortLeerjaarInzicht[]> {
    // TODO haal data op via endpoint /connect/dashboard/3.0/vestiging/{vestigingUUID}/studiewijzers/platform/{platformNaam}/vaksectie/{vaksectieNaam}
    return new Observable<OnderwijssoortLeerjaarInzicht[]>();
  }

  public fetchLeermiddelen(): Observable<Leermiddel[]> {
    return this.dashboardApi.getLeermiddelenVoorMedewerkerVestigingen().pipe(map(lms => lms.map(lm => ({
      ...lm,
      aantalLeerlingen: lm.aantalLeerlingenMetLicentie
    }))));
  }

  public postLeermiddelvakkoppeling(newLeermiddelState: Leermiddel): Observable<Leermiddel> {
    return this.dashboardApi.postLeermiddel({
      leermiddelUUID: newLeermiddelState.UUID,
      body: {
        naam: undefined, // Want al bekend bij backend en readonly in spec.
        UUID: undefined,
        aantalLeerlingenMetLicentie: undefined,
        gekoppeldAanVakken: newLeermiddelState.gekoppeldAanVakken,
        vakkenVanGelicenceerdeLeerlingen: undefined
      }
    }).pipe(
      map(lm => ({
      ...lm,
      aantalLeerlingen: lm.aantalLeerlingenMetLicentie
    })));
  }

    public fetchDatumbereikenVanVestiging(vestiging: Vestiging): Observable<Datumbereik[]> {
        return this.dashboardApi.getDatumbereiken({ vestigingUUID: vestiging.uuid }).pipe(map(dbs => dbs.map(db => ({
            epochSeconds: db['epoch-seconds'], // Is het niet fijn dat we deze service laag hebben?
            periode: db.periode,
            hasData: db.hasData
        }))));
    }
}
