import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Leermiddel, Vak } from 'src/app/state/leermiddelvakkoppeling/leermiddelvakkoppeling.state';

@Component({
    selector: 'app-leermiddelvakkoppeling-vakken',
    templateUrl: './leermiddelvakkoppeling-vakken.component.html',
    styleUrls: ['./leermiddelvakkoppeling-vakken.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeermiddelvakkoppelingVakkenComponent implements OnInit {

    @Input()
    public viewModel: LeermiddelvakkoppelingVakkenComponentViewModel;

    @Input()
    public leermiddel: Leermiddel;

    public vakken: Vak[];

    public isOpen: boolean;

    public cooldown: boolean = false;

    public gekoppeldeVakken: Vak[];

    constructor() { }

    ngOnInit(): void {
        this.gekoppeldeVakken = [...this.leermiddel.gekoppeldAanVakken].sort((a, b) => a?.naam?.toLocaleLowerCase()?.localeCompare(b?.naam?.toLocaleLowerCase()));
        this.vakken = [...this.leermiddel.vakkenVanGelicenceerdeLeerlingen].sort((a, b) => a?.naam?.toLocaleLowerCase()?.localeCompare(b?.naam?.toLocaleLowerCase()));
    }

    open(): void {
        this.isOpen = true;
        this.cooldown = true;
        setTimeout(() => this.cooldown = false, 200);
    }

    sluit(): void {
        if (this.cooldown) {
            return;
        }

        this.isOpen = false;

        // Check of er wel wijzigingen zijn gedaan voordat we storeLeermiddel aanroepen
        if (
            this.gekoppeldeVakken.length !== this.leermiddel.gekoppeldAanVakken.length ||
            this.gekoppeldeVakken.filter(
                v => !!this.leermiddel.gekoppeldAanVakken.find(vak => vak.UUID === v.UUID)
            ).length !== this.gekoppeldeVakken.length) {
            this.viewModel.storeLeermiddel({ ...this.leermiddel, gekoppeldAanVakken: this.gekoppeldeVakken });
        }
    }

    ontkoppel(event): void {
        event.stopPropagation();
        this.viewModel.storeLeermiddel({ ...this.leermiddel, gekoppeldAanVakken: [] });
    }

    toggleKoppeling(vak: Vak): void {
        if (this.gekoppeldeVakken.find(v => v.UUID === vak.UUID)) {
            this.gekoppeldeVakken = this.gekoppeldeVakken.filter(v => v.UUID !== vak.UUID);
        }
        else {
            this.gekoppeldeVakken = [...this.gekoppeldeVakken, vak].sort((a, b) => a?.naam?.toLocaleLowerCase()?.localeCompare(b?.naam?.toLocaleLowerCase()));
        }
    }

    public isSelected(vak: Vak): boolean {
        return !!this.gekoppeldeVakken.find(v => v.UUID === vak.UUID);
    }
}

export interface LeermiddelvakkoppelingVakkenComponentViewModel {
    storeLeermiddel: (leermiddel: Leermiddel) => void;
}

