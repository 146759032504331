import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseFacade } from '../base.facade';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { Leermiddel } from './leermiddelvakkoppeling.state';
import { selectLeermiddelen, selectLeermiddelenMetVak, selectLeermiddelenZonderVak, selectLoading, selectOpen } from './leermiddelvakkoppeling.selectors';
import { fetchLeermiddelvakkoppeling, setLeermiddelVakKoppelingOpen, storeLeermiddel } from './leermiddelvakkoppeling.actions';

@Injectable()
export class LeermiddelvakkoppelingNewFacade extends BaseFacade {
    constructor(store: Store<AppState>) {
        super(store);
    }

    getLeermiddelenMetVak(): Observable<Leermiddel[]> {
        return this.select(selectLeermiddelenMetVak);
    }

    getLeermiddelenZonderVak(): Observable<Leermiddel[]> {
        return this.select(selectLeermiddelenZonderVak);
    }

    hasLeermiddelen(): Observable<boolean> {
        return this.select(selectLeermiddelen).pipe(map(leermiddelen => leermiddelen.length > 0));
    }

    setLeermiddelVakKoppelingOpen(open: boolean): void {
        this.dispatch0(fetchLeermiddelvakkoppeling);
        this.dispatch(setLeermiddelVakKoppelingOpen, open);
    }

    isOpen(): Observable<boolean> {
        return this.select(selectOpen);
    }

    isLoading(): Observable<boolean> {
        return this.select(selectLoading);
    }

    storeLeermiddel(newLeermiddelState: Leermiddel): void {
        return this.dispatch(storeLeermiddel, newLeermiddelState);
    }
}
