import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {SomtodayService} from '../services/somtoday.service';
import {payload} from '../state/payload';
import {
  setGeselecteerdeKoppelpartij,
  setKoppelpartijen
} from '../state/privacydashboard/privacydashboard.actions';
import {of} from 'rxjs';
import {ApolloError} from 'apollo-client';
import {reportError, setErrorMessages} from '../state/errors/errors.actions';
import {HttpErrorResponse} from '@angular/common/http';
import {AppState, PRIVACY_DASHBOARD_FORBIDDEN} from '../state/app.state';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {
  selectGeselecteerdeKoppelpartij,
} from '../state/privacydashboard/privacydashboard.selectors';
import {Store} from '@ngrx/store';
import {deleteKoppeling, saveKoppeling} from '../state/avgdashboard-preview/avgdashboard-preview.actions';

@Injectable()
export class PrivacydashboardPreviewEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => {
      return getDashboardPath(p?.routerState?.url) === '/privacydashboard-preview'
        && !p?.routerState?.root?.firstChild?.params?.Koppelpartij;
    }),
    switchMap(() => this.somtodayService.fetchKoppelpartijen()),
    map(payload),
    map(setKoppelpartijen),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService) {
  }
}

@Injectable()
export class PrivacydashboardPreviewGeselecteerdeKoppelpartijEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    withLatestFrom(this.store.select(selectGeselecteerdeKoppelpartij)),
    filter(([{payload: p}, k]) => {
      return p?.routerState?.url?.includes('/privacydashboard-preview/koppelpartij')
        && p?.routerState?.root?.firstChild?.params?.koppelpartij
        && !k;
    }),
    switchMap(([{payload: p}, _]) => {
      return this.somtodayService.fetchKoppelpartij(p.routerState.root.firstChild.params.koppelpartij);
    }),
    map(
      item => ({
        koppelpartij: item,
      })
    ),
    map(setGeselecteerdeKoppelpartij),
    catchError(e => {
      if (e.status === 404) {
        // Link door naar privacy dashboard en geef error toaster. Negeer de error vervolgens.
        this.router.navigateByUrl('privacydashboard-preview');
        this.toastr.error('Dit product is bij ons niet bekend.');
        return of(e).pipe(map(setErrorMessages));
      } else {
        return of(e).pipe(
          map((networkError: HttpErrorResponse) => networkError.status === 403 ? {
            networkError,
            errorMessage: PRIVACY_DASHBOARD_FORBIDDEN
          } : {networkError}),
          map(networkError => new ApolloError(networkError)),
          map(payload),
          map(reportError));
      }
    })));

  constructor(private router: Router, private actions$: Actions, private somtodayService: SomtodayService, private toastr: ToastrService, private store: Store<AppState>) {
  }
}

@Injectable()
export class PrivacydashboardPreviewSaveKoppeling {
  $ = createEffect(() => this.actions$.pipe(
    ofType(saveKoppeling),
    switchMap((action) => this.somtodayService.postSimpeleKoppeling(action.koppelpartij.uuid)),
    tap((koppelpartij) => {
      if (koppelpartij) this.toastr.success('Gelukt! De koppeling met ' + koppelpartij.koppelpartijNaam + ' is succesvol aangemaakt.');
    }),
    map((koppelpartij) => ({koppelpartij})),
    map(setGeselecteerdeKoppelpartij),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>, private toastr: ToastrService) {}
}

@Injectable()
export class PrivacydashboardPreviewDeleteKoppeling {
  $ = createEffect(() => this.actions$.pipe(
    ofType(deleteKoppeling),
    switchMap((action) => this.somtodayService.deleteSimpeleKoppeling(action.koppelpartij.uuid)),
    tap((koppelpartij) => {
      if (koppelpartij) this.toastr.info('De koppeling met ' + koppelpartij.koppelpartijNaam + ' is succesvol verwijderd.');
    }),
    map((koppelpartij) => ({koppelpartij})),
    map(setGeselecteerdeKoppelpartij),
    catchError(e => of(e).pipe(
      map((networkError: HttpErrorResponse) => networkError.status === 403 ?
        {networkError, errorMessage: PRIVACY_DASHBOARD_FORBIDDEN} : {networkError}),
      map(networkError => new ApolloError(networkError)),
      map(payload),
      map(reportError)))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private store: Store<AppState>, private toastr: ToastrService) {}
}
