import {Component, OnInit, ChangeDetectionStrategy, HostListener} from '@angular/core';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {ModalwindowService} from '../../services/modalwindow.service';
import {RangeLinechartModelKeyBased} from '../../layout/range-linechart/range-linechart.model';
import {LesgroepInformatieRegel, TotaalInformatieRegel} from '../../state/vakdashboard/vakdashboard.state';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {encodeSlashes} from '../../services/url.encode.service';

@Component({
  selector: 'app-vakdashboard-table',
  templateUrl: './vakdashboard-table.component.html',
  styleUrls: ['./vakdashboard-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakdashboardTableComponent implements OnInit {
  public loading: Observable<boolean> = this.store.selectLoading();

  public resize: BehaviorSubject<void> = new BehaviorSubject<void>(null);

  constructor(public store: VakdashboardNewFacade, private modalwindowService: ModalwindowService, private router: Router) { }

  ngOnInit(): void {}

  public getRangelineChart(row: LesgroepInformatieRegel): RangeLinechartModelKeyBased[] {
    return row?.lesgroepGebruik.map(lg => ({key: lg.key, values: {min: lg.gebruik, avg: lg.gebruik, max: lg.gebruik}}));
  }

  public getGemiddeldGebruik(row: LesgroepInformatieRegel | TotaalInformatieRegel): string {
    return row?.gemiddeldGebruik?.toFixed(1) + ' keer per week';
  }

  public openActiefHelp(): void {
    this.modalwindowService.toonOnderwerp('actief');
  }

  public openGebruikHelp(): void {
    this.modalwindowService.toonOnderwerp('gebruik');
  }

  getDocentNamen(row: LesgroepInformatieRegel): string {
    let result = '';
    if (row?.docentNamen) {
      for (const docent of row.docentNamen.slice(0, 3)) {
        result = result.concat(docent + '<br>');
      }
      if (row.docentNamen.length > 3) {
        result = result.concat('<span class="label"> +' + (row.docentNamen.length - 3) + '</span>');
      }
      if (row.docentNamen.length === 0) {
        result = '-';
      }
    }
    return result;
  }

  public getLesgroepNaam(row: LesgroepInformatieRegel): Observable<string> {
    return this.resize.pipe(
      map(() => {
        let lesgroepNaam = row.lesgroepNaam;
        if (window.innerWidth < 1200) {
          if (lesgroepNaam.length > 12) {
            lesgroepNaam = lesgroepNaam.substr(0, 12).trim().concat('...');
          }
        }
        return lesgroepNaam;
      })
    );
  }

  @HostListener('window:resize')
  onResize(): void {
    this.resize.next();
  }

  navigeerNaarKlassendashboard(row: LesgroepInformatieRegel): void {
    const split = decodeURIComponent(this.router.url).split(new RegExp('[?/]'));
    const vak = split[2];
    const os = split[3];

    this.router.navigate(['/klassendashboardnew/' + vak + '/' + encodeSlashes(os) + '/' + row?.lesgroepUUID], {queryParamsHandling: 'preserve'});
  }
}
