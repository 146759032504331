import {getSelectors} from '@ngrx/router-store';
import {selectRouter} from '../app.state';
import {createSelector} from '@ngrx/store';
import {Params} from '@angular/router';
import {stringify, stringifyUrl, UrlObject} from 'query-string';
import {Payload} from '../payload';
import {encode} from '../../services/url.encode.service';

export const {
  selectCurrentRoute,   // select the current route
  selectFragment,       // select the current route fragment
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = getSelectors(selectRouter);

const selectBooleanQueryParameter = (name: string) => createSelector(
  selectQueryParam(name),
  v => v !== 'false'
);
export const selectKoppelpartijParam = selectRouteParam('koppelpartij');
export const selectOAuthClientParam = selectRouteParam('oauthclient');
export const selectOmgevingParam = selectRouteParam('omgeving');
export const selectWebserviceParam = selectRouteParam('webservice');
export const selectVak = selectRouteParam('vak');
export const selectVakParam = selectQueryParam('vak');
export const selectOsParam = selectQueryParam('ows');
export const selectVestigingParam = selectQueryParam('vestiging');
export const selectVestigingenParam = selectQueryParam('vestigingen');
export const selectPeriodeParam = selectQueryParam('periode');
export const selectBegindatumParam = selectQueryParam('begindatum');
export const selectGroeperenOpVak = selectBooleanQueryParameter('groeperenOpVak');
export const selectGroeperenopUitgever = selectBooleanQueryParameter('groeperenOpUitgever');

export const getDashboardPath = (url: string) => (/^(\/[^\/?]+)/.exec(url) || [])[0];

export const updateUrl = (url, queryParams, update: Payload<Params>) => {
  const params = {...queryParams, ...update.value};
  const [path] = url.split('?');
  return `${path}?${stringify(params)}`;
};
export const selectUpdatedUrl = createSelector(
  selectUrl,
  selectQueryParams,
  updateUrl
);

export const updateUrlDeleted = (url, queryParams, update: {key: string, value: string}) => {
  let params = {...queryParams};
  if (params[update.key]?.includes(update.value)) {
    if (typeof params[update.key] === 'string') {
      if (Object.keys(params).length) {
        delete params[update.key];
      } else {
        params = [];
      }
    } else {
      params[update.key].splice(params[update.key].indexOf(update.value), 1);
    }
  }
  const [path] = url.split('?');
  return `${path}?${stringify(params)}`;
};
export const selectUpdatedUrlDeleted = createSelector(
  selectUrl,
  selectQueryParams,
  updateUrlDeleted
);

export const updateUrlAdded = (url, queryParams, update: {key: string, value: string}) => {
  const params = {...queryParams};
  if (params[update.key] !== undefined && params[update.key] !== null) {
    if (typeof queryParams[update.key] === 'string') {
      params[update.key] = [params[update.key], update.value];
    } else {
      params[update.key] = [...params[update.key], update.value];
    }
  } else {
    params[update.key] = update.value;
  }
  const [path] = url.split('?');
  return `${path}?${stringify(params)}`;
};
export const selectUpdatedUrlAdded = createSelector(
  selectUrl,
  selectQueryParams,
  updateUrlAdded
);

const createUrl = (path: string, query: Params) => `${path}?${stringify(query)}`;

export const selectPathWithFilter = createSelector(
  selectQueryParams,
  (params, {value}: Payload<string>) => createUrl(value, params)
);
export const selectTransformFilterToPath = createSelector(
  selectUrl,
  selectRouteParams,
  selectQueryParams,
  (url: string, path: Params, query: Params, {value: fn}: Payload<(url: string, p: Params, q: Params) => UrlObject>) => {
    return stringifyUrl(fn(url, encodeEach(path), query)).replace('(', '%28').replace(')', '%29');
  }
);
export const encodeEach = (params: Params): Params => Object.entries(params)
    .map(([key, value]) => ({key, value: Array.isArray(value) ? value.map(encode) : encode(value)}))
    .reduce((object, {key, value}) => { object[key] = value; return object; }, {} as Params);

const selectFragmentId = createSelector(
  selectRouteParams,
  selectGroeperenOpVak,
  ({vak, onderwijssoort, ean}: Params, groeperenOpVak: boolean): string => {
  if (ean !== undefined) {
    return ean;
  } else if (groeperenOpVak) {
    return `${vak}-${onderwijssoort}`;
  } else {
    return `${onderwijssoort}-${vak}`;
  }
}
);
export const selectGoBackUrl = createSelector(
  selectQueryParams,
  selectFragmentId,
  (query: Params, fragmentIdentifier, {value: url}: Payload<string>) =>
    stringifyUrl({url, query, fragmentIdentifier})
);
