<div class="grayout" (window:scroll)="onScroll()" [style.top]="verticalOffset + 'px'" (click)="closeVenster()">
</div>
<div class="uitlegoverlay" [style.top]="verticalOffset + 'px'">
  <div class="header">
    <i *ngIf="selectie!==4 && selectie !== 6 && selectie !== 7" class="icon-wit-vraagteken"></i>
    <i *ngIf="selectie==4" class="icon-studiewijzer-popup"></i>
    <i *ngIf="selectie == 6" class="icon-koppeling-wit"></i>
    <i *ngIf="selectie == 7" class="icon-kado"></i>
    <span *ngIf="selectie!==4 && selectie !== 6 && selectie !== 7">Uitleg en definities</span>
    <span *ngIf="selectie==4 && platformVakData" [innerHTML]="platformVakData.platform + ' - ' + platformVakData.vak"></span>
    <span *ngIf="selectie == 6">Nieuwe koppeling</span>
    <span *ngIf="selectie == 7">Wat is er nieuw?</span>
    <a (click)="closeVenster()">
      <i class="icon-wit-kruis"></i>
    </a>
  </div>
  <div *ngIf="selectie == 0 || selectie == 1" class="knoppen">
    <a (click)="veranderSelectie('actief')"
       [class.selected]="selectie == 0">
      Actief
    </a>
    <a (click)="veranderSelectie('gebruik')"
       [class.selected]="selectie == 1">
      Gebruik
    </a>
  </div>
  <article *ngIf="selectie == 0">
    <h3>Waarvan recent actief</h3>
    <p>
      In de staafgrafieken lees je af hoeveel van de leerlingen met activatie minstens één keer gebruik hebben gemaakt
      van het lesmateriaal in de geselecteerde periode. Hoe verder de balk gevuld is, hoe meer leerlingen actief zijn geweest.
    </p>
    <img alt="Voorbeeld van een staafdiagram" src="../../assets/img/staafdiagramuitleg.svg">
    <p>
      Op een aantal overzichten zie je de verspreiding van de actieve leerlingen van de onderliggende lesgroepen.
      Het gevulde gedeelte van de balk geeft de minimale en maximale percentages aan
      en bij de streep lees je het gemiddelde af.
    </p>
  </article>
  <article *ngIf="selectie == 1 ">
    <h3>Gebruik</h3>
    <p>
      In de lijngrafieken lees je af hoe actief leerlingen het leermiddel gebruikt hebben.
      Hoe hoger de lijn komt, hoe meer gebruik er van gemaakt is.
      Van links naar rechts zie je het verloop door de tijd heen.
    </p>
    <img alt="Voorbeeld van een grafiek" src="../../assets/img/lijngrafiekuitleg.svg">
    <p>
      Op een aantal overzichten zie je de verspreiding van het gebruik van de onderliggende lesgroepen.
      Het gearceerde gedeelte toont de minimale en maximale gebruiksniveau aan en bij de lijn lees je het gemiddelde af.
    </p>
  </article>
  <article *ngIf="selectie == 2 ">
    <h3>Gebruik</h3>
    <p>
      De pijlen geven een trend aan in de activiteit van de leerling. Daarmee kun je aflezen of het gebruik gestegen,
      gedaald, of gelijk gebleven is ten opzichte van de voorgaande periode.
    </p>
    <img alt="Voorbeeld van een grafiek" src="../../assets/img/gebruikperweekuitleg.svg">
    <h3>Op basis van welke periode?</h3>
    <p>
      De trend wordt gebaseerd op een vergelijking met de voorgaande periode. Wanneer bijvoorbeeld week 42 geselecteerd is, 
      wordt de activiteit van week 42 vergeleken met de activiteit van week 41.
    </p>
  </article>
  <article *ngIf="selectie == 3">
    <h3>Klikratio</h3>
    <p>Deze waarde geeft aan hoe actief leerlingen de studiewijzerlinkjes gebruiken.</p>
    <p>De klikratio van één link is het percentage leerlingen dat de link geopend heeft.
      De klikratio van een vak of platform is het gemiddelde van de klikratios van de linkjes die bij dat vak of platform horen.</p>
  </article>
  <article *ngIf="selectie == 5">
    <h3>Niet geclassificeerde methodes</h3>
    <p>Bij onderstaande leermiddelen is het niet gelukt om ze te herleiden tot een vak.</p>
    <p>Dit kan twee oorzaken hebben: het leermiddel is niet gekoppeld aan een vak, of de leerling zit niet in een lesgroep voor dat vak. Om dit op te lossen kan het leermiddel alsnog worden gekoppeld aan een vak dat de leerling volgt via de Leermiddel-Vak Koppelingen.</p>
  </article>

  <article *ngIf="selectie == 4 && platformVakData">
    <div class="platformvak" [innerHTML]="platformVakData.platform + ' - ' + platformVakData.vak"></div>
    <app-connect-loader [loading]="!platformVakData.tabelData" [whitebackground]="true" [showText]="false">
    <app-table *ngIf="platformVakData.tabelData" [model]="platformVakData.tabelData | swplatformvak"></app-table>
    </app-connect-loader>
  </article>

  <article *ngIf="selectie == 6 && beschikbareKoppelingen">
    <app-connect-loader [loading]="!beschikbareKoppelingen" [whitebackground]="true" [showText]="false">
      <app-table *ngIf="beschikbareKoppelingen" [model]="beschikbareKoppelingen | beschikbareKoppelingen" (rowClick)="nieuweKoppeling($event)"></app-table>
    </app-connect-loader>
    <div class="meerKoppelingenLinkBox" (click)="doorverwijzingPartnerprogramma()">
      <div><!-- divje voor uitlijning --></div>
      <p class="meerKoppelingen">Wil je koppelen met een applicatie die <br/> hier niet tussen staat? <br /> Verwijs hen dan door naar het <em>Connect Platform</em></p>
      <div><i class="icon-arrow"></i></div>
    </div>
  </article>

  <article *ngIf="selectie == 7">
    <div class="update" *ngFor="let update of updates" id="update-{{update.id}}">
      <div class="unread" [ngClass]="{'hidden': !update.unread}"></div>
      <span class="titel">{{update.titel}}</span>
      <div class="text" [innerHTML]="update.text"></div>
    </div>
  </article>

  <article *ngIf="selectie == -1">Er is helaas geen informatie beschikbaar.</article>

</div>
