import {Component, OnInit, ChangeDetectionStrategy} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {PrivacydashboardFacade} from '../state/privacydashboard/privacydashboard.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../state/app.state';
import {selectKoppelpartijen, selectSemParties} from '../state/privacydashboard/privacydashboard.selectors';
import {AVGExtendedKoppelpartij} from '../dto/avg-dashboard-model-classes';
import {Router} from '@angular/router';
import {fetchSemParties, setGoBackURL} from '../state/privacydashboard/privacydashboard.actions';
import {getDashboardPath} from '../state/router/router.selectors';
import { AVGSemPartyMarketplaceEntry } from 'src/generated/avg-client';
import { map, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-aanbod-koppelpartijen',
  templateUrl: './aanbod-koppelpartijen.component.html',
  styleUrls: ['./aanbod-koppelpartijen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AanbodKoppelpartijenComponent implements OnInit {
  loading$ = this.store.selectLoading();

  koppelpartijen$: Observable<AVGExtendedKoppelpartij[]>;
  semParties: Observable<AVGSemPartyMarketplaceEntry[]>;

  public isPreview: boolean;

  constructor(private store: PrivacydashboardFacade, private appState: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.store.selectSemMarketplaceEntries()
    .pipe(takeWhile(semParties => !semParties, true)).subscribe(() => {
        this.appState.dispatch(fetchSemParties());
    });

    this.koppelpartijen$ = this.appState.select(selectKoppelpartijen);
    this.semParties = this.appState.select(selectSemParties);
    this.isPreview = getDashboardPath(this.router.url) === '/privacydashboard-preview';
  }

  doorverwijzingPartnerprogramma(): void {
    window.open('https://som.today/somtoday-connect-partnerprogramma/');
  }

  openKoppelpartij(uuid: string): void {
    if (this.isPreview) {
      this.appState.dispatch(setGoBackURL({url: 'privacydashboard-preview'}));
      this.router.navigateByUrl('/privacydashboard-preview/koppelpartij/details/' + uuid);
    } else {
      this.appState.dispatch(setGoBackURL({url: 'privacydashboard/aanbod'}));
      this.router.navigateByUrl('/privacydashboard/koppelpartij/details/' + uuid);
    }
  }

  openSemParty(uuid: string): void {
      if (this.isPreview) {
          this.appState.dispatch(setGoBackURL({url: 'privacydashboard-preview'}));
          this.router.navigateByUrl('privacydashboard-preview/semkoppelpartij/details/' + encodeURIComponent(uuid));
      } else {
          this.appState.dispatch(setGoBackURL({url: 'privacydashboard/aanbod'}));
          this.router.navigateByUrl('privacydashboard/semkoppelpartij/details/' + encodeURIComponent(uuid) + '?menu=datatoegang');
      }
  }
}
