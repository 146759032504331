import {combineLatest, Observable} from 'rxjs';
import {selectOsParam, selectVakParam} from '../router/router.selectors';
import {map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BaseFacade} from '../base.facade';
import {Store} from '@ngrx/store';
import {AppState} from '../app.state';
import {
  Bazenbanner,
  LeermiddelGrafiekPunt,
  SchooldashboardItemContent,
  SchooldashboardItemHeader
} from './schooldashboard.state';
import {
  selectActivatieGraad,
  selectBazenBanner, selectEducatieveAanbieders,
  selectGeactiveerdeLicenties, selectGemiddeldGebruik, selectItemByOnderwijssoortVak, selectLeermiddelGebruik,
  selectLicentiesPerLeerling, selectOnderwijssoortContent,
  selectOnderwijssoorten, selectVakContent,
  selectVakken
} from './schooldashboard.selectors';
import {fetchSchooldashboardOnderwijssoortDetails, fetchSchooldashboardVakDetails, resetSchooldashboard} from './schooldashboard.actions';

@Injectable()
export class SchooldashboardNewFacade extends BaseFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getVakken(): Observable<SchooldashboardItemHeader[]> {
    return this.select(selectVakken);
  }

  getVakContent(naam: string): Observable<SchooldashboardItemContent[]>{
    this.select(selectVakContent(naam)).pipe(take(1))
      .subscribe(vakContent => {
        if (vakContent === null) {
          this.dispatch(fetchSchooldashboardVakDetails, {naam});
        }
      });
    return this.select(selectVakContent(naam));
  }

  hasVakken(): Observable<boolean> {
    return this.select(selectVakken).pipe(map(vakken => vakken.length > 0));
  }

  hasVakContent(naam: string): Observable<boolean> {
    return this.select(selectVakContent(naam)).pipe(map(content => content != null));
  }

  getOnderwijssoorten(): Observable<SchooldashboardItemHeader[]> {
    return this.select(selectOnderwijssoorten);
  }

  getOnderwijssoortContent(naam: string): Observable<SchooldashboardItemContent[]>{
    this.select(selectOnderwijssoortContent(naam)).pipe(take(1))
      .subscribe(osContent => {
        if (osContent === null) {
          this.dispatch(fetchSchooldashboardOnderwijssoortDetails, {naam});
        }
      });
    return this.select(selectOnderwijssoortContent(naam));
  }

  hasOnderwijssoorten(): Observable<boolean> {
    return this.select(selectOnderwijssoorten).pipe(map(onderwijssoorten => onderwijssoorten.length > 0));
  }

  hasOnderwijssoortContent(naam: string): Observable<boolean> {
    return this.select(selectOnderwijssoortContent(naam)).pipe(map(content => content != null));
  }

  getOpenRows(): Observable<string> {
    return combineLatest([this.select(selectVakParam), this.select(selectOsParam)]).pipe(
      map(([vak, os]) => {
        if (vak !== undefined && os !== undefined) {
          return vak.concat(os);
        } else if (vak !== undefined) {
          return vak;
        } else {
          return os;
        }
      })
    );
  }

  getBazenbanner(): Observable<Bazenbanner> {
    return this.select(selectBazenBanner);
  }

  hasBazenbanner(): Observable<boolean> {
    return this.select(selectBazenBanner).pipe(map(bazenbanner => bazenbanner != null));
  }

  getAantalLicentiesPerLeerling(): Observable<number> {
    return this.select(selectLicentiesPerLeerling);
  }

  getGeactiveerdeLicenties(): Observable<number> {
    return this.select(selectGeactiveerdeLicenties);
  }

  getEducatieveAanbieders(): Observable<number> {
    return this.select(selectEducatieveAanbieders);
  }

  getActivatieGraad(): Observable<number> {
    return this.select(selectActivatieGraad);
  }

  getLeermiddelGebruik(): Observable<LeermiddelGrafiekPunt[]> {
    return this.select(selectLeermiddelGebruik);
  }

  getGemiddeldGebruik(): Observable<number> {
    return this.select(selectGemiddeldGebruik);
  }

  getItemByOnderwijssoortVak(onderwijssoortNaam: string, vakNaam: string, isGegroepeerdOpOnderwijssoort: boolean): Observable<SchooldashboardItemContent> {
    return this.select(selectItemByOnderwijssoortVak(onderwijssoortNaam, vakNaam, isGegroepeerdOpOnderwijssoort));
  }

  invalidateState(): void {
    this.dispatch0(resetSchooldashboard);
  }

}
