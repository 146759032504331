<div class="container">
  <div #rangebar class="rangebar" *ngIf="valid; else invalid"
       (mousemove)="onMouseMove($event)" (mouseleave)="onMouseLeave()">
    <div class="percentages">
      <div>0%</div>
      <div>100%</div>
    </div>
    <div class="baseline">
      <div #range class="range"></div>
      <div #avg class="avg"></div>
      <div #dot class="dot"></div>
    </div>
    <div class="values">
      <div class="onderschrift">{{tooltip}}</div>
      <div class="gebruik">Tussen {{ minPercentage }} en {{ maxPercentage }}</div>
    </div>
  </div>
  <ng-template #invalid>Ongeldige gegevens {{range | json}}.</ng-template>
</div>
