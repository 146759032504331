import {NgModule} from '@angular/core';
import {Routes, RouterModule, Router, Event as NgEvent, Scroll, UrlTree} from '@angular/router';
import { HomeComponent } from './home/home.component';
import {StyleGuideComponent} from './style-guide/style-guide.component';
import {ChangelogComponent} from './changelog/changelog.component';
import {AuthGuard} from './auth/auth.guard';
import {SchooldashboardComponent} from './old-dashboarding/schooldashboard/schooldashboard.component';
import {OAuthRouteComponent} from './oauth-route/oauth.route.component';
import {VakloosleermiddeldashboardComponent} from './old-dashboarding/vakloosleermiddeldashboard/vakloosleermiddeldashboard.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {VakdashboardComponent} from './old-dashboarding/vakdashboard/vakdashboard.component';
import {KlassendashboardComponent} from './old-dashboarding/klassendashboard/klassendashboard.component';
import {StudiewijzerlinkjesdashboardComponent} from './studiewijzerlinkjes/studiewijzerlinkjesdashboard/studiewijzerlinkjesdashboard.component';
import {LeermiddelendashboardComponent} from './old-dashboarding/leermiddelendashboard/leermiddelendashboard.component';
import {ViewportScroller} from '@angular/common';
import {filter} from 'rxjs/operators';
import {PrivacydashboardComponent} from './privacydashboard/privacydashboard.component';
import {VestigingendropdownComponent} from './layout/vestigingendropdown/vestigingendropdown.component';
import {InstellingComponent} from './layout/instelling/instelling.component';
import { KoppelingAanmakenPageComponent } from './koppeling-aanmaken-page/koppeling-aanmaken-page.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import {DatatoegangComponent} from './datatoegang/datatoegang.component';
import {WebserviceComponent} from './webservice/webservice.component';
import { KoppelpartijDetailsPageComponent } from './koppelpartij-details-page/koppelpartij-details-page.component';
import { AanbodKoppelpartijenComponent } from './aanbod-koppelpartijen/aanbod-koppelpartijen.component';
import { DevKoppelpartijBewerkenPageComponent } from './dev-koppelpartij-bewerken-page/dev-koppelpartij-bewerken-page.component';
import {KoppelpartijDetailsPreviewPageComponent} from './koppelpartij-details-preview-page/koppelpartij-details-preview-page.component';
import {StudiewijzerlinkjesNewComponent} from './new-dashboard/studiewijzerlinkjes-new/studiewijzerlinkjes-new.component';
import {
  VestigingendropdownNewComponent
} from './new-dashboard/vestigingendropdown-new/vestigingendropdown-new.component';
import {KlassendashboardNewComponent} from './new-dashboard/klassendashboard-new/klassendashboard-new.component';
import {SchooldashboardNewComponent} from './new-dashboard/schooldashboard-new/schooldashboard-new.component';
import {VakdashboardNewComponent} from './new-dashboard/vakdashboard-new/vakdashboard-new.component';
import { SemKoppelpartijDetailsPageComponent } from './sem-koppelpartij-details-page/sem-koppelpartij-details-page.component';


const PRODUCT_NAME = 'Somtoday Connect';

const privacydashboardSubMenu: SubMenu = {
  items: [
    {
      title: 'Gekoppelde producten',
      icon: 'koppeling',
      url: '/privacydashboard/koppelingen',
    },
    {
      title: 'Datatoegang',
      icon: 'shield',
      url: '/privacydashboard/datatoegang',
    },
    {
      title: 'Aanbod producten',
      icon: 'apps',
      url: '/privacydashboard/aanbod',
    },
  ]
};

const routes: Routes = [
  {
    canActivate: [AuthGuard],
    path: 'schooldashboard',
    component: SchooldashboardComponent,
    data: {subtitle: `Schooldashboard`, icon: 'icon-school-dashboard', component: VestigingendropdownComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'schooldashboardnew',
    component: SchooldashboardNewComponent,
    data: {subtitle: `Schooldashboard`, icon: 'icon-school-dashboard', component: VestigingendropdownNewComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'leermiddelendashboard',
    component: LeermiddelendashboardComponent,
    data: {subtitle: `Leermiddelendashboard`, icon: 'icon-leermiddelen-dashboard', component: VestigingendropdownComponent}},
  {
    canActivate: [AuthGuard],
    path: 'landelijkvakdashboard/:vak/:onderwijssoort',
    component: VakdashboardComponent,
    data: {subtitle: `Landelijk Vakdashboard`, icon: 'icon-vak-dashboard', component: VestigingendropdownComponent}},
  {
    canActivate: [AuthGuard],
    path: 'vakdashboard/:vak/:onderwijssoort',
    component: VakdashboardComponent,
    data: {subtitle: `Vakdashboard`, icon: 'icon-vak-dashboard', component: VestigingendropdownComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'vakdashboardnew/:vak/:onderwijssoort',
    component: VakdashboardNewComponent,
    data: {subtitle: `Vakdashboard`, icon: 'icon-vak-dashboard', component: VestigingendropdownNewComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'vakoverstijgenddashboard/:ean',
    component: VakloosleermiddeldashboardComponent,
    data: {subtitle: `Vakoverstijgend leermiddel dashboard`, icon: 'icon-vak-dashboard', component: VestigingendropdownComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'lesgroepdashboard/:vak/:onderwijssoort/:lesgroepuuid',
    component: KlassendashboardComponent,
    data: {subtitle: `Klassendashboard`, icon: 'icon-lesgroep-detail-dashboard', component: VestigingendropdownComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'klassendashboardnew/:vak/:onderwijssoort/:lesgroepuuid',
    component: KlassendashboardNewComponent,
    data: {subtitle: `Klassendashboard`, icon: 'icon-lesgroep-detail-dashboard', component: VestigingendropdownNewComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'studiewijzerlinkjesdashboard',
    component: StudiewijzerlinkjesdashboardComponent,
    data: {subtitle: 'Studiewijzerlinkjes', icon: 'icon-sw', component: VestigingendropdownComponent}
  },
  {
    canActivate: [AuthGuard],
    path: 'studiewijzerlinkjesdashboardnew',
    component: StudiewijzerlinkjesNewComponent,
    data: {subtitle: 'Studiewijzerlinkjes', icon: 'icon-sw', component: VestigingendropdownNewComponent}
  },
  {
    path: 'privacydashboard',
    redirectTo: '/privacydashboard/koppelingen',
    pathMatch: 'full'
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/koppelingen',
    component: PrivacydashboardComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent,
      subMenu: privacydashboardSubMenu,
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/aanbod',
    component: AanbodKoppelpartijenComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent,
      subMenu: privacydashboardSubMenu
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/koppelingaanmaken/:koppelpartij',
    component: KoppelingAanmakenPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent
    },
    canDeactivate: [CanDeactivateGuard]
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/koppelingbewerken/:koppelpartij',
    component: KoppelingAanmakenPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent
    },
    canDeactivate: [CanDeactivateGuard]
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/koppelpartij/details/:koppelpartij',
    component: KoppelpartijDetailsPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/semkoppelpartij/details/:koppelpartij',
    component: SemKoppelpartijDetailsPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/datatoegang',
    component: DatatoegangComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent,
      subMenu: privacydashboardSubMenu,
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard/datatoegang/:webservice',
    component: WebserviceComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard',
      component: InstellingComponent,
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard-preview',
    component: AanbodKoppelpartijenComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard-preview',
      component: InstellingComponent,
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard-preview/koppelpartij/details/:koppelpartij',
    component: KoppelpartijDetailsPreviewPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard-preview',
      component: InstellingComponent
    }
  },
  {
    canActivate: [AuthGuard],
    path: 'privacydashboard-preview/semkoppelpartij/details/:koppelpartij',
    component: SemKoppelpartijDetailsPageComponent,
    data: {
      subtitle: 'Privacydashboard',
      icon: 'icon-privacy-dashboard-preview',
      component: InstellingComponent
    }
  },
  {
    path: 'oauth',
    component: OAuthRouteComponent,
    data: {
      subtitle: `Inloggen`,
      icon: 'icon-oog'
    }
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {subtitle: `Er is een fout opgetreden`, icon: 'icon-error', iconFont: true}
  },
  {
    path: 'guide',
    component: StyleGuideComponent,
    data: {
      subtitle: `Somtoday Connect guide`,
      icon: 'icon-oog',
      iconFont: true
    },
  },
  {
    path: 'changelog',
    component: ChangelogComponent,
    data: {
      subtitle: `Somtoday Connect Changelog`,
      icon: 'icon-oog',
      iconFont: true
    },
  },
  {
    canActivate: [AuthGuard],
    path: 'dev/koppelpartij/:omgeving/:oauthclient',
    component: DevKoppelpartijBewerkenPageComponent,
    data: {
      subtitle: 'DEV - Koppelpartij bewerken',
      icon: 'icon-privacy-dashboard'
    }
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: HomeComponent,
    data: {
      subtitle: `Welkom bij ${PRODUCT_NAME}`,
      icon: 'icon-lesstof-huiswerk',
      iconFont: true
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setHistoryScrollRestoration('manual');
    this.router.events.pipe(
      filter((e: NgEvent): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        const nav = router.getCurrentNavigation();
        const current = nav.finalUrl.root.children.primary?.segments.join('/');
        const prev = (nav.initialUrl as UrlTree)?.root.children.primary?.segments.join('/');
        // Do not scroll to origin when only query parameters are updated
        const position: [number, number] = current === prev ? viewportScroller.getScrollPosition() : [0, 0];
        viewportScroller.scrollToPosition(position);
      }
    });
  }
}

export interface SubMenu {
  items: SubMenuItem[];
}

export interface SubMenuItem {
  title: string;
  icon: string;
  url: string;
}
