import {Component} from '@angular/core';
import {Vestiging} from '../../../generated/graphql';
import {Observable} from 'rxjs';
import {AnalyticsService, Metrics} from '../../services/analytics.service';
import {
  VestigingExt,
  selectVestigingen,
  selectHuidigeVestiging
} from '../../old-dashboarding/state/schooldashboard/schooldashboard.selectors';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {FilterService} from '../../services/filter.service';
import {Params} from '@angular/router';
import {stringifyUrl, UrlObject} from 'query-string';
import {getDashboardPath} from '../../state/router/router.selectors';

@Component({
  selector: 'app-vestigingendropdown',
  templateUrl: './vestigingendropdown.component.html',
  styleUrls: ['./vestigingendropdown.component.scss']
})
export class VestigingendropdownComponent {
  public expanded = false;

  public get vestiging$(): Observable<Vestiging> {
    return this.store.select(selectHuidigeVestiging);
  }

  public get vestigingen$(): Observable<VestigingExt[]> {
    return this.store.select(selectVestigingen);
  }

  public set selectie({uuid}: Vestiging) {
    this.expanded = false;
    this.analyticsService.logEvent(Metrics.VESTIGINGCHANGE);
    this.filterService.transformFilterToPath(createUrl(uuid));
  }

  constructor(private analyticsService: AnalyticsService,
              private store: Store<AppState>,
              private filterService: FilterService) {}
}

const createUrl = (vestiging: string) => (currentUrl: string) => {
  const path = getDashboardPath(currentUrl);
  let url = path;
  switch (path){
    case '/vakdashboard':
    case '/lesgroepdashboard':
      url = '/schooldashboard';
      break;
  }
  return {url, query: {vestiging}};
};
