import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RangeModel, isValid} from '../model/range.model';
import {deelVeilig} from '../../services/veilig-delen';
@Component({
  selector: 'app-range-bar-new',
  templateUrl: './range-bar-new.component.html',
  styleUrls: ['./range-bar-new.component.scss'],
})
export class RangeBarNewComponent implements OnInit, AfterViewInit {
  public tooltip: string;
  private defaultTooltip: string;

  public valid = true;

  private lowerEdge: number;
  private higherEdge: number;

  public minPercentage: string;
  private avgPercentage: string;
  public maxPercentage: string;

  constructor() {
  }

  @Input() public range: RangeModel;

  @ViewChild('range') rangeEl: ElementRef;
  @ViewChild('avg') avgEl: ElementRef;
  @ViewChild('dot') dotEl: ElementRef;
  @ViewChild('rangebar') rangebarEl: ElementRef;

  ngOnInit(): void {
    this.valid = isValid(this.range);

    this.lowerEdge = deelVeilig(((this.range.avg + this.range.min) / 2), this.range.tot);
    this.higherEdge = deelVeilig(((this.range.avg + this.range.max) / 2), this.range.tot);

    this.minPercentage = this.getPercentage(deelVeilig(this.range.min, this.range.tot));
    this.avgPercentage = this.getPercentage(deelVeilig(this.range.avg, this.range.tot));
    this.maxPercentage = this.getPercentage(deelVeilig(this.range.max, this.range.tot));

    this.defaultTooltip = this.avgPercentage + ' actief';
    this.tooltip = this.defaultTooltip;
  }

  ngAfterViewInit(): void {
    this.rangeEl.nativeElement.style.left = this.minPercentage;
    this.rangeEl.nativeElement.style.width = this.getPercentage(deelVeilig((this.range.max - this.range.min), this.range.tot));
    this.avgEl.nativeElement.style.left = this.avgPercentage;
  }

  public onMouseMove(event: any): void {
    const width = this.rangebarEl.nativeElement.getBoundingClientRect().width;
    const left = this.rangebarEl.nativeElement.getBoundingClientRect().left;
    const mouseX = (event.clientX - left) / width;

    if (mouseX < this.lowerEdge) {
      this.tooltip = 'Laagste: ' + this.minPercentage;
      this.dotEl.nativeElement.style.left = this.minPercentage;
    } else if (mouseX > this.lowerEdge && mouseX < this.higherEdge) {
      this.tooltip = 'Gemiddeld: ' + this.avgPercentage;
      this.dotEl.nativeElement.style.left = this.avgPercentage;
    } else {
      this.tooltip = 'Hoogste: ' + this.maxPercentage;
      this.dotEl.nativeElement.style.left = this.maxPercentage;
    }
  }

  public onMouseLeave(): void {
    this.tooltip = this.defaultTooltip;
  }

  public getPercentage(value: number): string {
    return Math.round(value * 100) + '%';
  }
}
