import {Component, DoCheck, HostListener, OnDestroy, OnInit} from '@angular/core';
import {graph1} from '../../shared/colors';
import {FONT_FAMILY} from '../../shared/fonts';
import {GoogleChartInterface} from 'ng2-google-charts';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-range-linechart-voor-bazenbanner-new',
  templateUrl: './range-linechart-voor-bazenbanner-new.component.html',
  styleUrls: ['./range-linechart-voor-bazenbanner-new.component.scss']
})
export class RangeLinechartVoorBazenbannerNewComponent implements OnInit, OnDestroy, DoCheck {
  public chartData: GoogleChartInterface;

  private subscriptions: Subscription[] = [];
  private firstDraw = false;

  constructor(public store: SchooldashboardNewFacade) { }

  ngOnInit(): void {
    this.chartData = {
      chartType: 'AreaChart',
      dataTable: {
        cols: [
          {type: 'string', label: 'datum'},
          {type: 'number', label: 'gebruik'}
        ],
        rows: []
      },
      options: {
        hAxis: {
          gridlines: {
            color: 'none'
          },
          ticks: [],
          viewWindowMode: 'maximized',
          textPosition: 'none'
        },
        vAxis: {
          minValue: 0,
          gridlines: {
            color: 'none'
          },
          baseline: { color: 'none' },
          textPosition: 'none'
        },
        chartArea: {
          width: '100%',
          height: '100%',
          left: 7,
          right: 7,
          top: 7,
          bottom: 7,
        },
        backgroundColor: { fill: 'none' },
        legend: 'none',
        fontName: FONT_FAMILY,
        pointsVisible: false,
        colors: [graph1],
        areaOpacity: 0.05
      }
    };

    this.subscriptions.push(this.store.getLeermiddelGebruik().subscribe(lg => {
      if (lg) {
        this.chartData.dataTable.rows = lg.map(({datum, gebruik}) => {
          return ({c: [{v: datum}, {v: gebruik?.toFixed(0)}]});
        });
        if (Math.max(...lg.map(x => x.gebruik)) === 0) {
          this.chartData.options.vAxis.viewWindow = {
            min: 0,
            max: 1,
          };
        }
        this.chartData.component?.draw();
      }
    }));
  }

  ngDoCheck(): void {
    if ( !this.firstDraw && this.chartData.component?.getDataTable() !== undefined) {
      this.chartData.component?.draw();
      this.firstDraw = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.chartData.component !== null && this.chartData.component !== undefined) {
      this.chartData.component?.draw();
    }
  }

}
