import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LeermiddelenDashboardGQL, UitgeverOfVakGebruikVoorPeriode} from '../../../generated/graphql';
import {routerNavigatedAction} from '@ngrx/router-store';
import {getDashboardPath} from '../../state/router/router.selectors';
import {catchError, filter, map, mapTo, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {selectFilter} from '../state/leermiddelendashboard/leermiddelendashboard.selectors';
import {payload, payload2} from '../../state/payload';
import {setLeermiddelData, setLeermiddelendashboardGroepering} from '../state/schooldashboard/schooldashboard.actions';
import {of} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import {flow} from 'fp-ts/function';
import {FilterService} from '../../services/filter.service';
import {selectVestigingen} from '../state/schooldashboard/schooldashboard.selectors';

@Injectable()
export class LeermiddelendashboardEffect {
  load$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(action => getDashboardPath(action.payload?.routerState?.url) === '/leermiddelendashboard'),
    switchMap(() => this.store.select(selectFilter).pipe(take(1))),
    switchMap(f => this.gql.fetch({filter: f})),
    map(result => result.data),
    map(({vestigingen, leermiddelDashboard}) => payload2(leermiddelDashboard, vestigingen)),
    map(setLeermiddelData),
    catchError(err => of(err).pipe(map(flow(payload, reportError))))
  ));

  groepering$ = createEffect(() => this.actions$.pipe(
    ofType(setLeermiddelendashboardGroepering),
    tap(({value: groeperenOpUitgever}) => this.filterService.setFilterOption({groeperenOpUitgever})),
    mapTo([] as UitgeverOfVakGebruikVoorPeriode[]),
    withLatestFrom(this.store.select(selectVestigingen)),
    map(([data, vestigingen]) => payload2(data, vestigingen)),
    map(setLeermiddelData),
  ));

  constructor(private actions$: Actions,
              private store: Store<AppState>,
              private gql: LeermiddelenDashboardGQL,
              private filterService: FilterService) {
  }
}
