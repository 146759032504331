import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import {LesgroepControlsHeaderViewModel} from '../klassendashboard-controls-header/klassendashboard-controls-header.component';
import { VakdashboardNewFacade } from 'src/app/state/vakdashboard/vakdashboard.facade';
import {encodeSlashes} from '../../services/url.encode.service';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import {Datumbereik} from '../../state/dashboardsettings/dashboardsettings.state';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-klassendashboard-new',
  templateUrl: './klassendashboard-new.component.html',
  styleUrls: ['./klassendashboard-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardNewComponent implements OnInit, OnDestroy {

  public loading: Observable<boolean> = this.store.selectLoading();
  public initialLoadingFinished = false;

  public controlsViewmodel: LesgroepControlsHeaderViewModel;

  private subscriptions: Subscription[] = [];

  constructor(public store: KlassendashboardNewFacade,
              private vakdashboard: VakdashboardNewFacade,
              private settings: DashboardsettingsFacade,
              private filterService: FilterService,
              private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(
      combineLatest([
        this.vakdashboard.getVakNaamURL(),
        this.vakdashboard.getOnderwijssoortNaamURL(),
        this.store.getLesgroepNaam(),
        this.store.getPrevURL(),
        this.store.getNextURL()]
      ).subscribe((([vak, ows, lesgroepNaam, prevURL, nextURL]) => {
        this.controlsViewmodel = this.createViewmodel(vak, ows, lesgroepNaam, prevURL, nextURL);
      })));
    this.cdr.detectChanges();

    this.loading.pipe(
      takeWhile((loading) => loading === true, true),
    ).subscribe((loading) => {
      if (loading === false) {
        this.initialLoadingFinished = true;
      }
    });
  }

  private createViewmodel(vak: string, ows: string, lesgroepNaam: string, prevURL: string, nextURL: string): LesgroepControlsHeaderViewModel {
    const terugKnopViewmodel = {
      caption: 'Terug naar Vakdashboard',
      url: `/vakdashboardnew/${vak}/${encodeSlashes(ows)}`
    };

    const lesgroepSwitchViewmodel = {
      lesgroepNaam,
      prevURL,
      nextURL
    };

    const datepickerViewmodel = {
      onGetCurrentDatumbereik: this.settings.getDatumbereik(),
      onGetMogelijkeDatumbereiken: this.settings.getDatumbereiken(),
      onGetSelectedVestiging: this.settings.getSelectedVestiging(),

      toggle: () => this.settings.toggleGroepering(),
      setSelectedDatumbereik: (datumbereik: Datumbereik) => this.settings.setDatumbereik(datumbereik)
    };

    return {
      terugKnopViewmodel,
      lesgroepSwitchViewmodel,
      datepickerViewmodel
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onSelectieChanged(selectie: string): void {
    this.filterService.setFilterOption({selectie});
  }
}
