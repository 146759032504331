import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {
  DashboardFilterInput,
  LesgroepOverstijgendDagGebruik,
  LesgroepSchooldashboardGQL,
  OpleidingVakLesgroepDagGebruik,
  VakloosLeermiddelOpleidingDagGebruik
} from '../../../generated/graphql';
import {setSchooldashboardData, setVakdashboardData, setVakoverstijgendDashboardData} from '../state/schooldashboard/schooldashboard.actions';
import {payload} from '../../state/payload';
import {Store} from '@ngrx/store';
import {AppState} from '../../state/app.state';
import {BazenbannerData, RuweSchooldashboardData} from '../state/schooldashboard/schooldashboard.state';
import * as AppSelectors from '../../state/app.selectors';
import {rehydrateVestigingen} from '../../services/vestiging.service';
import {routerNavigatedAction} from '@ngrx/router-store';
import {getDashboardPath, selectGroeperenOpVak, selectRouteParam} from '../../state/router/router.selectors';
import {of, pipe} from 'rxjs';
import {reportError} from '../../state/errors/errors.actions';
import {selectOpenVakken} from '../state/schooldashboard/schooldashboard.selectors';
import {selectOpenOnderwijssoorten} from '../state/schooldashboard/schooldashboard.selectors';
import {vandaag} from '../../services/datumbereik';

@Injectable()
export class SchooldashboardEffect {
  schooldashboardData$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) => getDashboardPath(p?.routerState?.url) === '/schooldashboard'),
    switchMap(() => this.store.select(AppSelectors.selectAppFilter).pipe(take(1))),
    filter(current => hasFilterChanged(this.prevSchooldashboardFilter, current)),
    switchMap(f => {
      this.prevSchooldashboardFilter = f;
      return this.schooldashboardQuery.fetch({filter: f}); }),
    map(createRuweSchooldashboardState),
    withLatestFrom(
      this.store.select(AppSelectors.selectHuidigePeriode),
      this.store.select(selectOpenVakken),
      this.store.select(selectOpenOnderwijssoorten),
      of(vandaag())
    ),
    map(pipe(
      payload,
      setSchooldashboardData
    )),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  vakdashboardData$ =
    createEffect(() =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        filter(({payload: p}) => getDashboardPath(p?.routerState?.url) === '/vakdashboard'),
        switchMap(() =>
          this.store.select(AppSelectors.selectAppFilter).pipe(take(1))
        ),
        pipe(
          withLatestFrom(this.store.select(selectRouteParam('vak')), this.store.select(selectRouteParam('onderwijssoort'))),
          filter(([current, vak, onderwijssoort]: [DashboardFilterInput, string, string]) =>
          hasFilterChanged(this.prevVakdashboardFilter, current) || this.prevVak !== vak || this.prevOnderwijssoort !== onderwijssoort)
        ),
        switchMap(([f, vak, onderwijssoort]: [DashboardFilterInput, string, string]) => {
          this.prevVak = vak;
          this.prevOnderwijssoort = onderwijssoort;
          this.prevVakdashboardFilter = f;
          return this.schooldashboardQuery.fetch({filter: f});
        }),
        map(createRuweSchooldashboardState),
        withLatestFrom(
          this.store.select(selectGroeperenOpVak),
          this.store.select(selectRouteParam('vak')),
          this.store.select(selectRouteParam('onderwijssoort')),
          this.store.select(AppSelectors.selectHuidigePeriode),
          of(vandaag())
        ),
        map(pipe(
          payload,
          setVakdashboardData
        )),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      ));

  vakoverstijgenddashboard$ =
    createEffect(() =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        filter(({payload: p}) => getDashboardPath(p?.routerState?.url) === '/vakoverstijgenddashboard'),
        switchMap(() =>
          this.store.select(AppSelectors.selectAppFilter).pipe(take(1))
        ),
        pipe(
          withLatestFrom(this.store.select(selectRouteParam('ean'))),
          filter(([current, ean]: [DashboardFilterInput, string]) =>
          hasFilterChanged(this.prevVakoverstijgendDashboardFilter, current) || this.prevEan !== ean)
        ),
        switchMap(([f, ean]: [DashboardFilterInput, string]) => {
          this.prevEan = ean;
          this.prevVakoverstijgendDashboardFilter = f;
          return this.schooldashboardQuery.fetch({filter: f});
        }),
        map(createRuweSchooldashboardState),
        withLatestFrom(
          this.store.select(AppSelectors.selectHuidigePeriode),
          this.store.select(selectRouteParam('ean')),
          of(vandaag())),
        map(pipe(
          payload,
          setVakoverstijgendDashboardData
        )),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
      ));

  private prevSchooldashboardFilter: DashboardFilterInput;
  private prevVakdashboardFilter: DashboardFilterInput;
  private prevVak: string;
  private prevOnderwijssoort: string;
  private prevVakoverstijgendDashboardFilter: DashboardFilterInput;
  private prevEan: string;

  constructor(private actions$: Actions,
              private schooldashboardQuery: LesgroepSchooldashboardGQL,
              private store: Store<AppState>) {
  }
}

export const hasFilterChanged = (prev: DashboardFilterInput = {}, current: DashboardFilterInput = {}) =>
  prev.vestiging !== current.vestiging || prev.periode !== current.periode;

function createRuweSchooldashboardState({data}): RuweSchooldashboardData {
  return {ruweVakData: data.lesgroepschooldashboard.map(rehydrateDag<OpleidingVakLesgroepDagGebruik>(v => v.dag)),
    ruweVakloosData: data.vakloosschooldashboard.map(rehydrateDag<VakloosLeermiddelOpleidingDagGebruik>(v => v.dag)),
    ruweOngeclassificeerdeData: data.lesgroepOverstijgendDagGebruik.map(rehydrateDag<LesgroepOverstijgendDagGebruik>(v => v.dag)),
    ruweBazenbannerData: {...data.statistieken} as BazenbannerData,
    vestigingen: rehydrateVestigingen(data.vestigingen)};
}

function rehydrateDag<T = OpleidingVakLesgroepDagGebruik | VakloosLeermiddelOpleidingDagGebruik | LesgroepOverstijgendDagGebruik>(
  getDag: (v: T) => Date): (t: T) => T {
  return t => ({ ...t, dag: new Date(getDag(t))});
}
