import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath, selectCurrentRoute, selectQueryParams} from '../state/router/router.selectors';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {
  dashboardSettingsLoaded,
  datumbereikenFetched,
  fetchDatumbereiken,
  resetBazenbanner,
  setDatumbereik,
  setVestigingen,
  toggleGroepering
} from '../state/dashboardsettings/dashboardsettings.actions';
import { AppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { backendString } from '../stateless/datumbereik';
import {SchooldashboardNewFacade} from '../state/schooldashboard/schooldashboard.facade';
import {VakdashboardNewFacade} from '../state/vakdashboard/vakdashboard.facade';

@Injectable()
export class DashboardsettingsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction),
    filter(({payload: p}) =>
      getDashboardPath(p?.routerState?.url) === '/schooldashboardnew' ||
      getDashboardPath(p?.routerState?.url) === '/vakdashboardnew' ||
      getDashboardPath(p?.routerState?.url) === '/klassendashboardnew' ||
      getDashboardPath(p?.routerState?.url) === '/studiewijzerlinkjesdashboardnew'
    ),
    withLatestFrom(this.settings.hasAvailableVestigingen()),
    filter(([_, hasAvailableVestigingen]) => !hasAvailableVestigingen),
    switchMap(() => this.dashboardingService.fetchVestigingen()),
    map(vestigingen => vestigingen.sort((a, b) => (a.naam > b.naam) ? 1 : -1)),
    mergeMap(vestigingen => [setVestigingen(payload(vestigingen)), fetchDatumbereiken(null)]),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class GroeperingToggleEffect {
    $ = createEffect(() => this.actions$.pipe(
        ofType(toggleGroepering),
        withLatestFrom(this.appStore.select(selectQueryParams), this.appStore.select(selectCurrentRoute), this.settings.isGegroepeerdOpVak()),
        map(([_, params, route, isGegroepeerdOpVak]) => ([{ ...params, groeperenOpVak: !isGegroepeerdOpVak}, route])),
        tap(([params, route]) => {
            this.router.navigate([route.url], { queryParams: params, queryParamsHandling: 'merge', replaceUrl: true });
        }),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
    ), { dispatch: false });

    constructor(private actions$: Actions,
                private appStore: Store<AppState>,
                private router: Router,
                private settings: DashboardsettingsFacade) {
    }
}

@Injectable()
export class DoFetchDatumbereikenVanVestiging {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchDatumbereiken),
    map(({value: vestiging}) => vestiging),
    withLatestFrom(this.settings.getSelectedVestiging()),
    switchMap(([vestitingParam, vestigingUrl]) =>
      this.dashboardingService.fetchDatumbereikenVanVestiging(vestitingParam != null ? vestitingParam : vestigingUrl)),
    mergeMap(item => [datumbereikenFetched(payload(item)), dashboardSettingsLoaded()]),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(
    private actions$: Actions,
    private dashboardingService: DashboardingService,
    private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class DoSetDatumbereik {
    $ = createEffect(() => this.actions$.pipe(
        ofType(setDatumbereik),
        map(({value: datumbereik}) => datumbereik),
        withLatestFrom(this.settings.getDatumbereiken(), this.settings.getBegindatumParam(), this.settings.getPeriodeOrDefault()),
        filter(([datumbereik, datumbereiken, epochSeconds, periode]) =>
            !(datumbereik.periode === periode && backendString(datumbereik.epochSeconds) === epochSeconds)
            && datumbereiken.find(d => d.periode === datumbereik.periode && d.epochSeconds === datumbereik.epochSeconds) !== undefined),
        map(([datumbereik]) => datumbereik),
        withLatestFrom(this.appStore.select(selectQueryParams), this.appStore.select(selectCurrentRoute)),
        map(([datumbereik, params, route]) => ([{...params, begindatum: backendString(datumbereik.epochSeconds), periode: datumbereik.periode}, route])),
        map(([params, route]) => {
            this.schooldashboardStore.invalidateState();
            this.vakdashboardStore.invalidateState();
            this.router.navigate(route.url.toString().split(','), {queryParams: params, queryParamsHandling: 'merge', replaceUrl: true});
            return resetBazenbanner();
        }),
        catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
    ));

    constructor(
        private actions$: Actions,
        private router: Router,
        private appStore: Store<AppState>,
        private settings: DashboardsettingsFacade,
        private schooldashboardStore: SchooldashboardNewFacade,
        private vakdashboardStore: VakdashboardNewFacade,
        ) {
    }
}
