<div class="wrapper-size-restrict" (clickOutside)="closeDropdown()">
    <div class="container {{(dropdownExpanded | async) ? 'open' : ''}}" (click)="toggleDropdown()">
        <div class="selector clickable">
            <input type="text" class="bodyContent" #text [placeholder]="(viewModel.onGetSelected | async).naam"
                (keyup)="onType()" (keyup.enter)="onEnter()" [(ngModel)]="searchString">
              <div class="icon-keper {{(dropdownExpanded | async) ? 'open' : ''}}">
              </div>
        </div>
    </div>

    <div class="dropdown {{(dropdownExpanded | async) ? 'open' : ''}}">
        <div class="shadow"></div>
        <ng-container *ngFor="let optie of (filteredOptions | async);">
            <div class="optie {{optie.hasData ? 'clickable' : ''}} {{optie.naam === (viewModel.onGetSelected | async).naam ? 'bodyContentBold' : optie.hasData ? 'bodyContent' : 'bodyContentLight'}}"
                (click)="viewModel.doOnOptieSelected(optie)">
                <p>{{optie.naam}}</p>
            </div>
        </ng-container>
    </div>
</div>
