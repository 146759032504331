<div [class]="this.isContentOpen ? 'row open' : 'row closed'">
  <div class="header" (click)="toggle()">
    <div class="title">{{data.naam}}</div>
    <div class="numbers">
      <div>{{data.actieveLeerlingen?.toFixed(0)}}% actieve leerlingen</div>
      <div>{{data.gebruikPerWeek?.toFixed(1)}} keer p/w</div>
      <div><i [class]="this.isContentOpen ? 'icon-arrow-close' : 'icon-arrow-open'"></i></div>
    </div>
  </div>
  <div class="content" [@openContent]="this.isContentOpen ? 'open' : 'closed'">
    <table>
      <tr>
        <th>Niveau</th>
        <th>Lesgroepen</th>
        <th>Activatie</th>
        <th>Waarvan recent actief <i class="icon-vraagteken" (click)="openActiefHelp()"></i></th>
        <th>Gebruik per leerling <i class="icon-vraagteken" (click)="openGebruikHelp()"></i></th>
      </tr>
      <tr *ngFor="let row of this.content" class="clickable" (click)="openKlassenDashboard(row)">
        <td class="max-150"><span class="max-2-lines">{{row.naam}}</span></td>
        <td class="extra-padding-left">{{row.aantalLesgroepen}}</td>
        <td><app-activatiegetal [range]="getActivatieGetal(row)"></app-activatiegetal></td>
        <td><app-range-bar-new [range]="getRangeBar(row)"></app-range-bar-new></td>
        <td><app-range-linechart-keybased [range]="getRangelineChart(row)" [gemiddeldGebruik]="getGemiddeldGebruik(row)"></app-range-linechart-keybased></td>
        <td><i class="icon-arrow"></i></td>
      </tr>
    </table>
  </div>
</div>
