import {Component, OnInit, ChangeDetectionStrategy, OnDestroy} from '@angular/core';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import {AnalyticsService, Metrics} from '../../services/analytics.service';
import {FilterService} from '../../services/filter.service';
import {getDashboardPath} from '../../state/router/router.selectors';
import {Observable, Subscription} from 'rxjs';
import {Datumbereik, Vestiging} from 'src/app/state/dashboardsettings/dashboardsettings.state';
import {SchooldashboardNewFacade} from '../../state/schooldashboard/schooldashboard.facade';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {backendString} from '../../stateless/datumbereik';

@Component({
  selector: 'app-vestigingendropdown-new',
  templateUrl: './vestigingendropdown-new.component.html',
  styleUrls: ['./vestigingendropdown-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VestigingendropdownNewComponent implements OnInit, OnDestroy {
  public expanded = false;

  private subs: Subscription[] = [];
  private currentBereik: Datumbereik;

  public get vestiging$(): Observable<Vestiging> {
    return this.store.getSelectedVestiging();
  }

  public get vestigingen$(): Observable<Vestiging[]> {
    return this.store.getAvailableVestigingen();
  }

  public select(vestiging: Vestiging): void {
    if (!vestiging.heeftData) {
      return;
    }

    this.expanded = false;
    this.analyticsService.logEvent(Metrics.VESTIGINGCHANGE);
    this.schooldashboardStore.invalidateState();
    this.vakdashboardStore.invalidateState();
    this.klassendashboardStore.invalidateState();
    this.settings.fetchDatumbereiken(vestiging);
    this.filterService.transformFilterToPath(createUrl(vestiging.uuid, this.currentBereik));
  }

  constructor(public store: DashboardsettingsFacade,
              private schooldashboardStore: SchooldashboardNewFacade,
              private vakdashboardStore: VakdashboardNewFacade,
              private klassendashboardStore: KlassendashboardNewFacade,
              private analyticsService: AnalyticsService,
              private filterService: FilterService,
              private settings: DashboardsettingsFacade) {
  }

  ngOnInit(): void {
    this.subs.push(this.store.getDatumbereik().subscribe((bereik) => {
      this.currentBereik = bereik;
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

const createUrl = (vestiging: string, currentBereik: Datumbereik) => (currentUrl: string) => {
  const path = getDashboardPath(currentUrl);
  let url = path;
  switch (path){
    case '/vakdashboard':
    case '/lesgroepdashboard':
      url = '/schooldashboard';
      break;
    case '/vakdashboardnew':
    case '/klassendashboardnew':
      url = '/schooldashboardnew';
      break;
  }
  const queryParams: any = {vestiging};
  if (currentBereik) {
    queryParams.begindatum = backendString(currentBereik.epochSeconds);
    queryParams.periode = currentBereik.periode;
  }
  return {url, query: queryParams};
};
