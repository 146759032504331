import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {fetchDocenten, setDocenten, setVakdashboard} from '../state/vakdashboard/vakdashboard.actions';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {VakdashboardNewFacade} from '../state/vakdashboard/vakdashboard.facade';
import {SomtodayService} from '../services/somtoday.service';
import {dashboardSettingsLoaded} from '../state/dashboardsettings/dashboardsettings.actions';
import {Router} from '@angular/router';
import {
  fetchSchooldashboardOnderwijssoortDetails,
  fetchSchooldashboardVakDetails,
  setSchooldashboardOnderwijssoorten,
  setSchooldashboardVakken
} from '../state/schooldashboard/schooldashboard.actions';
import {SchooldashboardNewFacade} from '../state/schooldashboard/schooldashboard.facade';
import { backendString } from '../stateless/datumbereik';

@Injectable()
export class VakdashboardVakkenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === '/vakdashboardnew'),
    withLatestFrom(this.settings.isGegroepeerdOpVak(), this.schooldashboard.hasVakken(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpVak, hasVakken, settingsLoaded]) => isGegroepeerdOpVak && !hasVakken && settingsLoaded),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardVakken(begindatum, periode, vestigingUUID)),
    withLatestFrom(this.vakdashboard.getVakNaamURL()),
    mergeMap(([item, currentVak]) => [
      setSchooldashboardVakken(payload(item)),
      fetchSchooldashboardVakDetails(payload({naam: currentVak}))
    ]),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private vakdashboard: VakdashboardNewFacade, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class VakdashboardOnderwijssoortenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === '/vakdashboardnew'),
    withLatestFrom(this.settings.isGegroepeerdOpOnderwijssoort(), this.schooldashboard.hasOnderwijssoorten(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpOnderwijssoort, hasOnderwijssoorten, settingsLoaded]) => isGegroepeerdOpOnderwijssoort && !hasOnderwijssoorten && settingsLoaded),
    withLatestFrom(this.settings.getBegindatumForBackend(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, begindatum, periode, vestigingUUID]) => this.dashboardingService.fetchSchooldashboardOnderwijssoorten(begindatum, periode, vestigingUUID)),
    withLatestFrom(this.vakdashboard.getOnderwijssoortNaamURL()),
    mergeMap(([item, currentOS]) => [
      setSchooldashboardOnderwijssoorten(payload(item)),
      fetchSchooldashboardOnderwijssoortDetails(payload({naam: currentOS}))
    ]),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private vakdashboard: VakdashboardNewFacade, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class VakdashboardEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === '/vakdashboardnew' || getDashboardPath(this.router.url) === '/klassendashboardnew'),
    withLatestFrom(
      this.vakdashboard.isVakdashboardFilled(),
      this.settings.areSettingsLoaded(),
    ),
    filter(([_, isVakdashboardFilled, areSettingsLoaded]) => !isVakdashboardFilled && areSettingsLoaded),
    withLatestFrom(
      this.settings.getBegindatumForBackend(),
      this.settings.getPeriodeOrDefault(),
      this.settings.getVestigingUUID(),
      this.vakdashboard.getOnderwijssoortNaamURL(),
      this.vakdashboard.getVakNaamURL(),
    ),
    switchMap(([_, begindatum, periode, vestigingUUID, onderwijssoortNaam, vakNaam]) =>
      this.dashboardingService.fetchVakdashboard(begindatum, periode, vestigingUUID, onderwijssoortNaam, vakNaam)
      .pipe(
        map((data) => ({ onderwijssoort: onderwijssoortNaam, vak: vakNaam, regels: data.regels, totaalRij: data.totaal }))
      )),
    switchMap((item) => ([
      setVakdashboard(item),
      fetchDocenten()
    ])),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private vakdashboard: VakdashboardNewFacade, private schooldashboard: SchooldashboardNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class VakdashboardDocentenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchDocenten),
    withLatestFrom(
      this.vakdashboard.getUniqueDocentUUIDs(),
    ),
    switchMap(([_, docentUUIDs]) => this.somtodayService.fetchMedewerkerNamen(docentUUIDs)),
    map(payload),
    map(setDocenten),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private somtodayService: SomtodayService, private vakdashboard: VakdashboardNewFacade) {
  }
}
