import { createReducer, on } from '@ngrx/store';

import { LeermiddelvakkoppelingNewState } from './leermiddelvakkoppeling.state';
import { setLeermiddelen, setLeermiddelVakKoppelingOpen, leermiddelStored } from './leermiddelvakkoppeling.actions';

const initialState: LeermiddelvakkoppelingNewState = {
    leermiddelVakKoppelingOpen: false,
    leermiddelen: [],
    loading: true
};

export const leermiddelvakkoppelingNewReducer = createReducer(
    initialState,
    on(setLeermiddelen, (state, { value }) => ({ ...state, leermiddelen: value, loading: false })),
    on(leermiddelStored, (state, { value }) => ({
        ...state,
        leermiddelen: [ ...state.leermiddelen.filter(l => l.UUID !== value.UUID), value]
    })),
    on(setLeermiddelVakKoppelingOpen, (state, { value }) =>
    ({
        ...state,
        leermiddelVakKoppelingOpen: value,
        selectedLeermiddelVakKoppeling: null
    }))
);
