import {Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef} from '@angular/core';
import {VakdashboardNewFacade} from '../../state/vakdashboard/vakdashboard.facade';
import {ControlsHeaderViewModel} from '../controls-header/controls-header.component';
import {DashboardsettingsFacade} from '../../state/dashboardsettings/dashboardsettings.facade';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {takeWhile} from 'rxjs/operators';

@Component({
  selector: 'app-vakdashboard-new',
  templateUrl: './vakdashboard-new.component.html',
  styleUrls: ['./vakdashboard-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VakdashboardNewComponent implements OnInit, OnDestroy {

  public controlsViewmodel: ControlsHeaderViewModel;

  private subscriptions: Subscription[] = [];

  public loading: Observable<boolean> = this.store.selectLoading();
  public initialLoadingFinished = false;

  constructor(private settings: DashboardsettingsFacade, private store: VakdashboardNewFacade, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    const terugKnopViewmodel = {
        caption: 'Terug naar Schooldashboard',
        url: '/schooldashboardnew',
    };

    this.subscriptions.push(
      combineLatest([this.store.getHoofdItem(),
        this.store.getSubItem(),
        this.store.getPrevURL(),
        this.store.getNextURL()])
        .subscribe(([hoofdItem, subItem, prevURL, nextURL]) => {
          const vakOnderwijsSwitchViewmodel = {hoofdItem, subItem, prevURL, nextURL};
          this.controlsViewmodel = {
            terugKnopViewmodel,
            vakOnderwijsSwitchViewmodel
          };
          this.cdr.detectChanges();
        }));

    this.loading.pipe(
      takeWhile((loading) => loading === true, true),
    ).subscribe((loading) => {
      if (loading === false) {
        this.initialLoadingFinished = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
