import {Injectable} from '@angular/core';
import {AppState} from '../state/app.state';
import {Store} from '@ngrx/store';
import {Params, Router} from '@angular/router';
import {
  selectPathWithFilter,
  selectTransformFilterToPath,
  selectUpdatedUrl, selectUpdatedUrlAdded,
  selectUpdatedUrlDeleted
} from '../state/router/router.selectors';
import {payload} from '../state/payload';
import {selectUpdatedOpenVakkenOfOnderwijssoorten} from '../old-dashboarding/state/schooldashboard/schooldashboard.selectors';
import {take} from 'rxjs/operators';
import {UrlObject} from 'query-string';

@Injectable()
export class FilterService {
  constructor(private store: Store<AppState>, private router: Router) {
  }

  public setFilterOption(option: object): void {
    this.store.select(selectUpdatedUrl, payload(option)).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }

  public deleteFromFilterOption(option: { key: string, value: string }): void {
    this.store.select(selectUpdatedUrlDeleted, option).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }

  public addToFilterOption(option: { key: string, value: string }): void {
    this.store.select(selectUpdatedUrlAdded, option).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }

  public updateOpenVakkenOfOnderwijssoorten(vakOfOnderwijssoort: string): void {
    this.store.select(selectUpdatedOpenVakkenOfOnderwijssoorten, payload(vakOfOnderwijssoort)).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }

  public applyFilterToPath(path: string): void {
    this.store.select(selectPathWithFilter, payload(path)).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }

  public transformFilterToPath(fn: (url: string, path: Params, query: Params) => UrlObject): void {
    this.store.select(selectTransformFilterToPath, payload(fn)).pipe(take(1))
      .subscribe(url => this.router.navigateByUrl(url));
  }
}
