import {createSelector} from '@ngrx/store';
import {selectSchooldashboardNew} from '../app.state';

export const selectBazenBanner = createSelector(
  selectSchooldashboardNew,
  (state) => state.bazenbanner
);

export const selectLicentiesPerLeerling = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.licentiesPerLeerling
);

export const selectGeactiveerdeLicenties = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.geactiveerdeLicenties
);

export const selectEducatieveAanbieders = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.educatieveAanbieders
);

export const selectActivatieGraad = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.activatieGraad
);

export const selectLeermiddelGebruik = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.leermiddelGebruik
);

export const selectGemiddeldGebruik = createSelector(
  selectBazenBanner,
  (bazenbanner) => bazenbanner?.gemiddeldGebruik
);

export const selectVakken = createSelector(
  selectSchooldashboardNew,
  (state) => state.vakken.map(vak => vak.header)
);

export const selectVakContent = (naam: string) => createSelector(
  selectSchooldashboardNew,
  (state) => state.vakken.find(vak => vak.header.naam === naam)?.content
);

export const selectOnderwijssoorten = createSelector(
  selectSchooldashboardNew,
  (state) => state.onderwijssoorten.map(os => os.header)
);

export const selectOnderwijssoortContent = (naam: string) => createSelector(
  selectSchooldashboardNew,
  (state) => state.onderwijssoorten.find(os => os.header.naam === naam)?.content
);

export const selectItemByOnderwijssoortVak = (onderwijssoortNaam: string, vakNaam: string, isGegroepeerdOpOnderwijssoort: boolean) =>
  createSelector(
    selectSchooldashboardNew,
    (state) => isGegroepeerdOpOnderwijssoort
      ? state.onderwijssoorten.find(os => os.header.naam === onderwijssoortNaam)?.content?.find(c => c.naam === vakNaam)
      : state.vakken.find(os => os.header.naam === vakNaam)?.content?.find(c => c.naam === onderwijssoortNaam)
  );
