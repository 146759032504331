import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { environment } from 'src/environments/environment';
import {LeermiddelvakkoppelingFacade as LeermiddelvakkoppelingOldFacade} from '../old-dashboarding/state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';
import { LeermiddelvakkoppelingNewFacade } from '../state/leermiddelvakkoppeling/leermiddelvakkoppeling.facade';

@Component({
  selector: 'app-vestigingsinstellingen',
  templateUrl: './vestigingsinstellingen.component.html',
  styleUrls: ['./vestigingsinstellingen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VestigingsinstellingenComponent implements OnInit {
  expanded = false;

  constructor(private leermiddelVakKoppelingOldStore: LeermiddelvakkoppelingOldFacade, private leermiddelVakKoppelingStore: LeermiddelvakkoppelingNewFacade) { }

  ngOnInit(): void {
  }

  toggle(): void {
    this.expanded = !this.expanded;
  }

  close(): void {
    if (this.expanded) {
      this.expanded = false;
    }
  }

  openLeermiddelVakKoppeling(): void {
    this.leermiddelVakKoppelingOldStore.setLeermiddelVakKoppelingOpen(true);
  }

  openLeermiddelVakKoppelingNew(): void {
    this.leermiddelVakKoppelingStore.setLeermiddelVakKoppelingOpen(true);
  }
  
  isNietProductieOfTest(): boolean {
    return 'Test' !== environment.configurationName && 'Productie' !== environment.configurationName;
  }
}
