import moment, { Moment } from 'moment';

export function localDateStringToDisplayDateString(localDateString: string): string {
    if (localDateString === null || localDateString === undefined || localDateString === '') {
        return null;
    }
    const dateMoment: Moment = moment(localDateString);
    dateMoment.locale('nl');
    return dateMoment.format('D MMMM YYYY');
}

export function localDateStringToShortDisplayDateString(localDateString: string): string
{
  if (localDateString === null || localDateString === undefined || localDateString === '') {
    return null;
  }
  const dateMoment: Moment = moment(localDateString);
  dateMoment.locale('nl');
  return dateMoment.format('D-M-YYYY');
}

export function momentToLocalDate(dateMoment: Moment): string {
    return dateMoment?.format('YYYY-MM-DD');
}

export function startOfTerm(): Moment {
    if (moment().month(7) < moment())
        return moment().month(7).date(1);

    return moment(moment().year() - 1).month(7).date(1);
}

export function formatDate(date: string, format: string): string {
    let formattedDate = moment(date).format(format);
    if (formattedDate.endsWith('.'))
        formattedDate = formattedDate.substr(0, formattedDate.length - 1);
    return formattedDate;
}
