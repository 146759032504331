<div *ngIf="valid; else invalid" class="container">
  <div [class]="'ruimteBolletje ' + waarschuwing(avgOffset)">
    <div class="bolletje"></div>
    <div class="bolletjeinfo">
      <h3>Activatiegraad</h3>
      <ul>
        <li><i class="bolletje lage-activatie"></i> Laag: 0 - 30% </li>
        <li><i class="bolletje matige-activatie"></i> Matig: 31 - 60% </li>
      </ul>
    </div>
  </div>
  <span class="activatie">{{avgOffset | percent}}</span>
  <div class="values">Tussen {{minOffset | percent}} - {{maxOffset | percent}}</div>
</div>
<ng-template #invalid>##</ng-template>
