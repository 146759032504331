<div class="container" (clickOutside)="close()">
    <div class="row-radio">
        <app-input-radiobutton class="week" [viewModel]="weekRadioButtonViewModel"></app-input-radiobutton>
        <app-input-radiobutton class="maand" [viewModel]="maandRadioButtonViewModel"></app-input-radiobutton>
        <app-input-radiobutton class="jaar" [viewModel]="jaarRadioButtonViewModel"></app-input-radiobutton>
    </div>

    <div class="row-selector">
        <app-input-combobox class="combo-notjaar" *ngIf="(active$ | async) !== 'JAAR'" [viewModel]="leftComboViewModel"></app-input-combobox>
        <app-input-combobox class="combo-jaar {{(active$ | async) === 'JAAR' ? 'wide' : ''}}" [viewModel]="rightComboViewModel"></app-input-combobox>
    </div>
    <button class="button-bevestiging" (click)="confirm()">Bevestig</button>
</div>
