import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {KlassendashboardNewFacade} from '../../state/klassendashboard/klassendashboard.facade';

@Component({
  selector: 'app-klassendashboard-individueel-table-row',
  templateUrl: './klassendashboard-individueel-table-row.component.html',
  styleUrls: ['./klassendashboard-individueel-table-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KlassendashboardIndividueelTableRowComponent implements OnInit {

  constructor(store: KlassendashboardNewFacade) { }

  ngOnInit(): void {
  }

}
