import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {routerNavigatedAction} from '@ngrx/router-store';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {getDashboardPath} from '../state/router/router.selectors';
import {of} from 'rxjs';
import {reportError} from '../state/errors/errors.actions';
import {payload} from '../state/payload';
import {DashboardingService} from '../services/dashboarding.service';
import {
  fetchStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht,
  fetchStudiewijzerLinkjesPlatformDetails,
  fetchStudiewijzerLinkjesVakDetails, setStudiewijzerlinkjesAlgemeen,
  setStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht,
  setStudiewijzerLinkjesPlatformDetails,
  setStudiewijzerlinkjesPlatformen,
  setStudiewijzerLinkjesVakDetails,
  setStudiewijzerlinkjesVakken
} from '../state/studiewijzerlinkjes/studiewijzerlinkjes.actions';
import {StudiewijzerlinkjesNewFacade} from '../state/studiewijzerlinkjes/studiewijzerlinkjes.facade';
import {DashboardsettingsFacade} from '../state/dashboardsettings/dashboardsettings.facade';
import {dashboardSettingsLoaded} from '../state/dashboardsettings/dashboardsettings.actions';
import {Router} from '@angular/router';
import { backendString } from '../stateless/datumbereik';

@Injectable()
export class StudiewijzerlinkjesPlatformenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === '/studiewijzerlinkjesdashboardnew'),
    withLatestFrom(this.studiewijzerlinkjes.isGegroepeerdOpPlatform(), this.studiewijzerlinkjes.hasPlatformGebruik(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpPlatform, hasPlatformGebruik, areSettingsLoaded]) => isGegroepeerdOpPlatform && !hasPlatformGebruik && areSettingsLoaded),
    withLatestFrom(this.settings.getEpochSeconds(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, epochSeconds, periode, vestigingUUID]) => this.dashboardingService.fetchStudiewijzerlinkjesPlatform(backendString(epochSeconds), periode, vestigingUUID)),
    switchMap(item => ([
      setStudiewijzerlinkjesAlgemeen( { totaalAantalLinks: item.totaalAantalLinks, totaalAantalLesgroepen: item.totaalAantalLesgroepen }),
      setStudiewijzerlinkjesPlatformen({ platformGebruik: item.platformGebruik })
    ])),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private studiewijzerlinkjes: StudiewijzerlinkjesNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class StudiewijzerPlatformDetailsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchStudiewijzerLinkjesPlatformDetails),
    withLatestFrom(this.settings.getEpochSeconds(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([action, epochSeconds, periode, vestigingUUID]) => this.dashboardingService.fetchStudiewijzerPlatformDetails(backendString(epochSeconds), periode, vestigingUUID, action.platformNaam)
      .pipe(
        map(details => ({ details, naam: action.platformNaam })),
    )),
    map(setStudiewijzerLinkjesPlatformDetails),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class StudiewijzerlinkjesVakkenEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigatedAction, dashboardSettingsLoaded),
    filter(_ => getDashboardPath(this.router.url) === '/studiewijzerlinkjesdashboardnew'),
    withLatestFrom(this.studiewijzerlinkjes.isGegroepeerdOpVak(), this.studiewijzerlinkjes.hasVakGebruik(), this.settings.areSettingsLoaded()),
    filter(([_, isGegroepeerdOpVak, hasVakGebruik, areSettingsLoaded]) => isGegroepeerdOpVak && !hasVakGebruik && areSettingsLoaded),
    withLatestFrom(this.settings.getEpochSeconds(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([_, epochSeconds, periode, vestigingUUID]) => this.dashboardingService.fetchStudiewijzerlinkjesVak(backendString(epochSeconds), periode, vestigingUUID)),
    switchMap(item => ([
      setStudiewijzerlinkjesAlgemeen( { totaalAantalLinks: item.totaalAantalLinks, totaalAantalLesgroepen: item.totaalAantalLesgroepen }),
      setStudiewijzerlinkjesVakken({ vakGebruik: item.vakGebruik })
    ])),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private router: Router, private dashboardingService: DashboardingService, private studiewijzerlinkjes: StudiewijzerlinkjesNewFacade, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class StudiewijzerVakDetailsEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchStudiewijzerLinkjesVakDetails),
    withLatestFrom(this.settings.getEpochSeconds(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([action, epochSeconds, periode, vestigingUUID]) => this.dashboardingService.fetchStudiewijzerVakDetails(backendString(epochSeconds), periode, vestigingUUID, action.vakNaam)
      .pipe(
        map(details => ({ details, naam: action.vakNaam })),
      )),
    map(setStudiewijzerLinkjesVakDetails),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}

@Injectable()
export class StudiewijzerOnderwijssoortLeerjaarInzichtEffect {
  $ = createEffect(() => this.actions$.pipe(
    ofType(fetchStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht),
    withLatestFrom(this.settings.getEpochSeconds(), this.settings.getPeriodeOrDefault(), this.settings.getVestigingUUID()),
    switchMap(([action, epochSeconds, periode, vestigingUUID]) => this.dashboardingService.fetchOnderwijssoortLeerjaarInzicht(backendString(epochSeconds), periode, vestigingUUID, action.platformNaam, action.vakNaam)
      .pipe(
        map(onderwijssoortLeerjaarInzicht => ({ onderwijssoortLeerjaarInzicht, platformNaam: action.platformNaam, vakNaam: action.vakNaam })),
      )),
    map(setStudiewijzerLinkjesOnderwijssoortLeerjaarInzicht),
    catchError(err => of(err).pipe(map(e => reportError(payload(e)))))
  ));

  constructor(private actions$: Actions, private dashboardingService: DashboardingService, private settings: DashboardsettingsFacade) {
  }
}
