import {createSelector} from '@ngrx/store';
import {selectPrivacydashboard} from '../app.state';
import {Endpoint} from './privacydashboard.state';
import {selectQueryParam} from '../router/router.selectors';
import { semScopeDescriptions } from 'src/app/dto/sem-scope-descriptions';
import {AVGWritePermissie} from '../../dto/avg-dashboard-model-classes';

// Geeft alle koppelpartijen
export const selectKoppelpartijen = createSelector(
  selectPrivacydashboard,
  state => {
    return state.koppelpartijen;
  }
);

export const selectKoppelpartij = createSelector(
  selectKoppelpartijen,
  (koppelpartijen, props) => {
      return koppelpartijen.find(item => item.uuid === props.uuid);
  }
);

// Geeft alle koppelpartijen waar een koppeling mee is
export const selectKoppelingen = createSelector(
  selectKoppelpartijen,
  (koppelpartijen) => koppelpartijen.filter(item => item.koppelingen.length > 0)
);

export const selectGeselecteerdeKoppelpartijAlgemeen = createSelector(
  selectPrivacydashboard,
  state => {
    return state.geselecteerdeKoppelpartij;
  }
);

export const selectGeselecteerdeKoppelpartij = createSelector(
  selectGeselecteerdeKoppelpartijAlgemeen,
  state => {
    return state?.koppelpartij;
  }
);

export const selectGeselecteerdeKoppelingen = createSelector(
  selectGeselecteerdeKoppelpartijAlgemeen,
  state => {
    return state?.koppelingen;
  }
);

export const selectVestigingen = createSelector(
  selectPrivacydashboard,
  state => {
    return state?.vestigingen;
  }
);

export const selectGecategoriseerdeVeldPermissies = createSelector(
  selectGeselecteerdeKoppelpartij,
  selectGeselecteerdeKoppelingen,
  (geselecteerdeKoppelpartij, geselecteerdeKoppelingen, props) => {
    let vp = [];
    let optional = [];

    if (!props.getEmptyFields) {
      const uuids = geselecteerdeKoppelingen?.map(k => k.vestiging.UUID);
      if (uuids?.length > 0) {
        for (const uuid of uuids) {
          if (uuid === uuids[0]) {
            if (geselecteerdeKoppelingen?.length > 0) {
              vp = geselecteerdeKoppelingen.find(item => item.vestiging.UUID === uuid).veldpermissies.filter(i => i.verplicht && i.selected);
            }
          }
          if (geselecteerdeKoppelingen?.length > 0) {
            optional = optional
              .concat(geselecteerdeKoppelingen
                .find(item => item.vestiging.UUID === uuid).veldpermissies
                .filter(i => (!i.verplicht && i.selected))
              );
          }
        }
        for (const item of optional) {
          if (!vp.find(i => i.name === item.name && i.entityName === item.entityName)) {
            vp.push(item);
          }
        }
      }
    }

    if (vp.length === 0) {
      if (geselecteerdeKoppelpartij) vp = geselecteerdeKoppelpartij.benodigdeVeldpermissies;
      else return [];
    }

    const endpoints: Endpoint[] = [
      {naam: 'Leerling', writePermissies: false, categorien: [
        {naam: 'Personalia', velden: []},
        {naam: 'Plaatsing', velden: []},
        {naam: 'Onderwijsinhoudelijk', velden: []},
        {naam: 'Resultaten', velden: []}
      ]},
      {naam: 'Medewerker', writePermissies: false, categorien: [
        {naam: 'Personalia', velden: []},
        {naam: 'Aanstelling', velden: []}
      ]},
      {naam: 'Lesgroep', writePermissies: false, categorien: [
        {naam: 'Algemeen', velden: []},
      ]},
      {naam: 'Ouder/Verzorger', writePermissies: false,  categorien: [
        {naam: 'Personalia', velden: []},
        {naam: 'Leerlingen', velden: []}
      ]},
      {naam: 'Vestiging', writePermissies: false, categorien: [
          {naam: 'Algemeen', velden: [
              {
                naam: 'Naam',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              },
              {
                naam: 'Afkorting',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              },
              {
                naam: 'BRIN',
                entityIndex: 2,
                fieldIndex: null,
                selected: false,
                verplicht: true,
                properties: null,
                subFields: null,
              }
            ]},
      ]},
    ];

    const medewerkerEndpoint = endpoints.find( e => e.naam === 'Medewerker');
    const leerlingEndpoint = endpoints.find( e => e.naam === 'Leerling');
    const ouderEndpoint = endpoints.find( e => e.naam === 'Ouder/Verzorger');

    vp = [...vp].filter(a => !a.additionalProperties.find(p => p.type === 'NestedEntity'));
    if (geselecteerdeKoppelpartij) {
      vp = [...vp].sort((a, b) => {
        const elA = geselecteerdeKoppelpartij.benodigdeVeldpermissies.find(x => x.entityIndex === a.entityIndex && x.fieldIndex === a.fieldIndex);
        const elB = geselecteerdeKoppelpartij.benodigdeVeldpermissies.find(x => x.entityIndex === b.entityIndex && x.fieldIndex === b.fieldIndex);
        return (
          geselecteerdeKoppelpartij.benodigdeVeldpermissies.indexOf(elA) - geselecteerdeKoppelpartij.benodigdeVeldpermissies.indexOf(elB)
        );
      });
    }
    vp = [...vp].sort((a, b) => {
      if (a.entityIndex > b.entityIndex) { return 1; }
      else if (a.entityIndex < b.entityIndex) { return -1; }
      else { return 0; }
    });

    for (const permission of vp) {
      const permissionMapped = {
        naam: permission.name,
        entityIndex: permission.entityIndex,
        fieldIndex: permission.fieldIndex,
        selected: permission.selected,
        verplicht: permission.verplicht,
        properties: permission.additionalProperties,
        subFields: null,
      };
      const endpoint = endpoints.find(e => permission.entityName === e.naam);
      if (endpoint) {
        if (
          ['Leerling', 'Medewerker'].includes(endpoint.naam) &&
          ['Vestiging', 'Hoofdvestiging', 'Lesgroepen', 'Vakkeuzes', 'Is docent', 'Functie'].includes(permission.name)
        ) {
          endpoint.categorien[1].velden.push(permissionMapped);
        } else {
          endpoint.categorien[0].velden.push(permissionMapped);
        }
      }
      if (permission.entityName === 'Leerling referentie') {
        ouderEndpoint.categorien[1].velden.push(permissionMapped);
      }
      if (permission.entityName === 'Plaatsing') {
        leerlingEndpoint.categorien[1].velden.push(permissionMapped);
      }
      if (permission.entityName === 'Resultaten') {
        leerlingEndpoint.categorien[3].velden.push(permissionMapped);
      }
      if (permission.entityName === 'Medewerker pasfoto') {
        medewerkerEndpoint.categorien[0].velden.push(permissionMapped);
      }
      if (permission.entityName === 'Leerling pasfoto') {
        leerlingEndpoint.categorien[0].velden.push(permissionMapped);
      }
      if (permission.entityName === 'Huiswerk') {
        leerlingEndpoint.categorien[2].velden.push(permissionMapped);
      }
      if (permission.entityName.includes('account')) {
        const accountPermission = {
          naam: 'Account',
          entityIndex: null,
          fieldIndex: null,
          selected: permission.selected,
          verplicht: permission.verplicht,
          properties: geselecteerdeKoppelpartij?.writePermissies?.some(p => p.endpoint === permission.entityName) ? [{description: null, type: 'write'}] : null,
          subFields: [permissionMapped],
        };
        if (permission.entityName === 'Leerling account') {
          const veld = leerlingEndpoint.categorien[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              leerlingEndpoint.writePermissies = true;
            }
            leerlingEndpoint.categorien[0].velden.push(accountPermission);
          } else {
            veld.subFields.push(permissionMapped);
          }
        }
        if (permission.entityName === 'Medewerker account'){
          const veld = medewerkerEndpoint.categorien[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              medewerkerEndpoint.writePermissies = true;
            }
            medewerkerEndpoint.categorien[0].velden.push(accountPermission);
          } else {
            veld.subFields.push(permissionMapped);
          }
        }
        if (permission.entityName === 'Ouder/Verzorger account') {
          const veld = ouderEndpoint.categorien[0].velden.find(v => v.naam === 'Account');
          if (!veld) {
            if (accountPermission.properties) {
              ouderEndpoint.writePermissies = true;
            }
            ouderEndpoint.categorien[0].velden.push(accountPermission);
          } else {
            veld.subFields.push(permissionMapped);
          }
        }
      }
    }

    const writePermissionResultaten: AVGWritePermissie[] = geselecteerdeKoppelpartij?.writePermissies?.filter(p => p.endpoint === 'Resultaten');
    if (writePermissionResultaten?.length > 0) {
      leerlingEndpoint.writePermissies = true;
      for (const wp of writePermissionResultaten) {
        const resultatenPermission = {
          naam: wp.naam,
          entityIndex: null,
          fieldIndex: null,
          selected: false,
          verplicht: true,
          properties: [{description: null, type: 'write'}],
          subFields: null,
        };
        leerlingEndpoint.categorien[3].velden.push(resultatenPermission);
      }
    }

    return endpoints.map(e => {
      e.categorien = e.categorien.filter(c => c.velden.length > 0);
      return e;
    }).filter(e => e.categorien.length > 0);
  }
);

export const selectKoppelingenBeschikbaarForKoppelpartij = createSelector(
  selectVestigingen,
  selectGeselecteerdeKoppelingen,
  (vestigingen, koppelingen) => {
    return koppelingen?.length < vestigingen?.length;
  }
);

export const selectBeschikbareKoppelingen = createSelector(
  selectVestigingen,
  selectKoppelpartijen,
  (vestigingen, koppelingen) =>
    // Select alle koppelingen waarvoor nog vestigingen over zijn om mee te koppelen.
    koppelingen.map(
      item => ({
        koppelpartijNaam: item.koppelpartijNaam,
        koppelingen: item.koppelingen.map(koppeling => ({vestigingUUID: koppeling.vestiging.UUID})),
        uuid: item.uuid
      })
    ).filter(
      koppeling => !vestigingen.every(vestiging => (koppeling.koppelingen.map(kpl => kpl.vestigingUUID).includes(vestiging.UUID)))
    )
);

export const selectOudeWebservices = createSelector(
  selectPrivacydashboard,
  state => {
    if (state.webservices) {
      return [...state.webservices].sort((a, b) => {
        const riskA = ['WIS', 'UM'].includes(a.service) ? 0 : 1;
        const riskB = ['WIS', 'UM'].includes(b.service) ? 0 : 1;
        return (riskA !== riskB) ? (riskA > riskB ? 1 : -1) : (a.service > b.service ? 1 : -1);
    }); }
    return state.webservices;
  }
);

export const selectGeselecteerdeWebservice = createSelector(
  selectPrivacydashboard,
  state => {
    return state.geselecteerdeWebservice;
  }
);

export const selectActiveMenuItem = createSelector(
  selectQueryParam('menu'),
  menuItem => {
    return menuItem;
  }
);
export const selectGoBackURL = createSelector(
  selectPrivacydashboard,
  state => {
    return state.goBackURL;
  }
);

export const selectSemParties = createSelector(
  selectPrivacydashboard,
  state => {
    return state.semParties;
  }
);

export const selectSemPartyDetails = createSelector(
  selectPrivacydashboard,
  state => {
    return state.semPartyDetails;
  }
);

export const selectSemPartyAccessedFields = createSelector (
  selectSemPartyDetails,
  semPartyDetails => {
    return semPartyDetails?.scopes?.map(scope => semScopeDescriptions.find(description => description.mappedScope === scope));
  }
);
