import {DevKoppelpartijState} from './dev-koppelpartij.state';
import {createReducer, on} from '@ngrx/store';
import {
  addSchrijfpermissie,
  deleteSchrijfpermissie,
  setEndpoints,
  setKoppelpartij,
  setLeespermissies,
  setOAuthClient,
  setOrganisaties,
  setSchrijfpermissies,
  setSuccesfullySent,
  updateBenodigdeVeldpermissie,
  updateDocentLink, updateKoppelpartijAfbeeldingen,
  updateKoppelpartijBedrijfsnaam,
  updateKoppelpartijLogoUrl,
  updateKoppelpartijNaam,
  updateKoppelpartijOmschrijving, updateKoppelpartijOrganisaties,
  updateKorteOmschrijving,
  updateLeerlingLink,
  updateOpLeerlingNiveauGeautoriseerd,
} from './dev-koppelpartij.actions';
import {reset} from '../app.actions';

const initialState: DevKoppelpartijState = {
  koppelpartij: {
    uuid: null,
    koppelpartijNaam: null,
    koppelpartijOmschrijving: null,
    koppelpartijKorteOmschrijving: null,
    docentLink: null,
    leerlingLink: null,
    koppelpartijAfbeeldingen: [],
    oAuthClientId: null,
    oAuthClientOmgeving: null,
    koppelpartijBedrijfsnaam: null,
    koppelpartijLogoUrl: null,
    benodigdeVeldpermissies: [],
    writePermissies: [],
    endpoints: [],
    zichtbaarVoorOrganisaties: [],
    opLeerlingNiveauGeautoriseerd: false,
  },
  leespermissies: null,
  schrijfpermissies: null,
  organisaties: null,
  oauthclient: null,
  endpoints: null,
  succesfullySent: false,
};

export const devKoppelpartijReducer = createReducer(
    initialState,
    on(setKoppelpartij, (state, {koppelpartij}) => ({...state, koppelpartij: {
        uuid: koppelpartij.uuid ? koppelpartij.uuid : state.koppelpartij?.uuid,
        koppelpartijNaam: koppelpartij.koppelpartijNaam ? koppelpartij.koppelpartijNaam : state.koppelpartij?.koppelpartijNaam,
        koppelpartijOmschrijving: koppelpartij.koppelpartijOmschrijving ? koppelpartij.koppelpartijOmschrijving : state.koppelpartij?.koppelpartijOmschrijving,
        koppelpartijKorteOmschrijving: koppelpartij.koppelpartijKorteOmschrijving ? koppelpartij.koppelpartijKorteOmschrijving : state.koppelpartij?.koppelpartijKorteOmschrijving,
        docentLink: koppelpartij.docentLink ? koppelpartij.docentLink : state.koppelpartij?.docentLink,
        leerlingLink: koppelpartij.leerlingLink ? koppelpartij.leerlingLink : state.koppelpartij?.leerlingLink,
        koppelpartijAfbeeldingen: koppelpartij.koppelpartijAfbeeldingen ? koppelpartij.koppelpartijAfbeeldingen : state.koppelpartij?.koppelpartijAfbeeldingen,
        oAuthClientId: koppelpartij.oAuthClientId ? koppelpartij.oAuthClientId : state.koppelpartij?.oAuthClientId,
        oAuthClientOmgeving: koppelpartij.oAuthClientOmgeving ? koppelpartij.oAuthClientOmgeving : state.koppelpartij?.oAuthClientOmgeving,
        koppelpartijBedrijfsnaam: koppelpartij.koppelpartijBedrijfsnaam ? koppelpartij.koppelpartijBedrijfsnaam : state.koppelpartij?.koppelpartijBedrijfsnaam,
        koppelpartijLogoUrl: koppelpartij.koppelpartijLogoUrl ? koppelpartij.koppelpartijLogoUrl : state.koppelpartij?.koppelpartijLogoUrl,
        benodigdeVeldpermissies: koppelpartij.benodigdeVeldpermissies ? koppelpartij.benodigdeVeldpermissies.filter(v => !v.additionalProperties?.find(p => p.type === 'NestedEntity')) : state.koppelpartij?.benodigdeVeldpermissies,
        writePermissies: koppelpartij.writePermissies ? koppelpartij.writePermissies : state.koppelpartij?.writePermissies,
        endpoints: koppelpartij.endpoints ? koppelpartij.endpoints : state.koppelpartij?.endpoints,
        zichtbaarVoorOrganisaties: koppelpartij.zichtbaarVoorOrganisaties ? koppelpartij.zichtbaarVoorOrganisaties : state.koppelpartij?.zichtbaarVoorOrganisaties,
        opLeerlingNiveauGeautoriseerd: (koppelpartij.opLeerlingNiveauGeautoriseerd !== undefined) ? koppelpartij.opLeerlingNiveauGeautoriseerd : state.koppelpartij?.opLeerlingNiveauGeautoriseerd
      }, succesfullySent: false})),
    on(setLeespermissies, (state, {value}) => ({...state, leespermissies: value})),
    on(setSchrijfpermissies, (state, {value}) => ({...state, schrijfpermissies: value})),
    on(setOrganisaties, (state, {value}) => ({...state, organisaties: value})),
    on(setOAuthClient, (state, {value}) => ({...state, oauthclient: value, koppelpartij: {...state.koppelpartij, oAuthClientId: value.clientId }})),
    on(setEndpoints, (state, {value}) => ({...state, endpoints  : value})),
    on(updateKoppelpartijAfbeeldingen, (state, { koppelpartijAfbeeldingen }) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijAfbeeldingen}, succesfullySent: false})),
    on(updateKoppelpartijOmschrijving, (state, { koppelpartijOmschrijving }) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijOmschrijving}, succesfullySent: false})),
    on(updateKoppelpartijNaam, (state, { koppelpartijNaam}) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijNaam}, succesfullySent: false})),
    on(updateKoppelpartijBedrijfsnaam, (state, { koppelpartijBedrijfsnaam }) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijBedrijfsnaam}, succesfullySent: false})),
    on(updateKoppelpartijLogoUrl, (state, { koppelpartijLogoUrl }) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijLogoUrl}, succesfullySent: false})),
    on(updateDocentLink, (state, { docentLink }) => ({...state, koppelpartij: {...state.koppelpartij, docentLink}, succesfullySent: false})),
    on(updateLeerlingLink, (state, { leerlingLink }) => ({...state, koppelpartij: {...state.koppelpartij, leerlingLink}, succesfullySent: false})),
    on(updateKorteOmschrijving, (state, { koppelpartijKorteOmschrijving }) => ({...state, koppelpartij: {...state.koppelpartij, koppelpartijKorteOmschrijving}, succesfullySent: false})),
    on(updateOpLeerlingNiveauGeautoriseerd, (state) => {
      if (state.koppelpartij?.opLeerlingNiveauGeautoriseerd) {
        return ({...state, koppelpartij: {...state.koppelpartij, opLeerlingNiveauGeautoriseerd: !(state.koppelpartij.opLeerlingNiveauGeautoriseerd)}, succesfullySent: false});
      } else {
        return ({...state, koppelpartij: {...state.koppelpartij, opLeerlingNiveauGeautoriseerd: true}, succesfullySent: false});
      }
    }),
    on(updateKoppelpartijOrganisaties, (state, { zichtbaarVoorOrganisaties }) => ({...state, koppelpartij: {...state.koppelpartij, zichtbaarVoorOrganisaties}, succesfullySent: false})),
    on(updateBenodigdeVeldpermissie, (state, { benodigdeVeldpermissies }) => {
      const filteredPermissies = state.koppelpartij?.benodigdeVeldpermissies?.filter(veld => !benodigdeVeldpermissies.find( veldToUpdate => (veldToUpdate.entityIndex === veld.entityIndex && veldToUpdate.fieldIndex === veld.fieldIndex)));
      if (filteredPermissies && filteredPermissies?.length > 0) {
        return {...state,
          koppelpartij: {
            ...state.koppelpartij,
            benodigdeVeldpermissies: [...filteredPermissies, ...benodigdeVeldpermissies]
          },
          succesfullySent: false
        };
      } else {
        return {...state,
          koppelpartij: {
            ...state.koppelpartij,
            benodigdeVeldpermissies: [...benodigdeVeldpermissies]
          },
          succesfullySent: false
        };
      }
    }),
    on(addSchrijfpermissie, (state, {schrijfpermissie}) => ({...state, koppelpartij: {...state.koppelpartij, writePermissies: (state.koppelpartij?.writePermissies?.length > 0) ? [...state.koppelpartij?.writePermissies, schrijfpermissie] : [schrijfpermissie]}, succesfullySent: false})),
    on(deleteSchrijfpermissie, (state, {schrijfpermissie}) => ({...state, koppelpartij: {...state.koppelpartij, writePermissies: [...state.koppelpartij?.writePermissies?.filter(wp => wp.index !== schrijfpermissie.index)]}, succesfullySent: false})),
    on(setSuccesfullySent, (state, {succesfullySent}) => ({...state, succesfullySent })),
    on(reset, _ => initialState)
);
